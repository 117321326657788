import React from "react";
import { Row, Col } from "antd";
import Image from "../../../../statics/assets/Frames/why-it-works/frame2Image.svg";
import frame from "../../../../statics/assets/Frames/why-it-works/Frame2.svg";
import "./section-two.style.css";
import PaperHeader from "../../../paper-header";
import WavesFrame from "../../../waveFrame";

const SectionTwo = () => {
  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="section-two-main app-padding">
        <Col xs={{ span: 22 }} md={{ span: 10 }}>
          <PaperHeader
            title={"Rewarding Epik Effort"}
            subTitle={`Kids and teens demonstrate Epik Efforts ™ and celebrate special occasions that inspire the need for a gift. 
            Families and friends give the Epik Gift Card as a special reward that feels fun and purposeful.`}
            fontSize="text-2lg"
            titleClass="bold mb-20"
            titleColor="#CE0F69"
            subTitleColor="#1B1B5B"
          />
          <PaperHeader
            headerClass="mt-3"
            title={"Spending"}
            subTitle={`Kids and teens can redeem the Epik Gift Card at their favorite family-friendly places online or in-store, to 
            pay for stuff like food, entertainment, apparel, accessories… and more!`}
            fontSize="text-2lg"
            titleClass="bold mb-20"
            titleColor="#CE0F69"
            subTitleColor="#1B1B5B"
          />
          <PaperHeader
            headerClass="mt-3"
            title={"Community & Giving Back"}
            subTitle={`Families and friends love celebrating kids and teens who perform Epik Efforts ™ by rewarding them with the Epik Gift 
            Card. We love to partner with epic, non-profit organizations that support causes benefiting kids and families.`}
            fontSize="text-2lg"
            titleClass="bold mb-20"
            titleColor="#CE0F69"
            subTitleColor="#1B1B5B"
          />
        </Col>

        <Col xs={{ span: 22 }} md={{ span: 12, offset: 2 }}>
          <div
            className="right-frame text-center"
            style={{ backgroundImage: `url(${frame})` }}
          >
            <div className="frame-inner-img">
              <img className="blue-border" src={Image} alt="Epik Gift Card" />
            </div>
          </div>
        </Col>

        <Col span={24}>
          <div className="text-center mt-5">
          <PaperHeader
            title={"The Possibilities are Endless"}
            subTitle={`The Epik Gift Card is the first, multi-store gift card for kids and teens, redeemable virtually anywhere.`}
            fontSize="app-secondary-title"
            titleClass="bold mb-20"
            // titleColor="#CE0F69"
            subTitleColor="#1B1B5B"
          />
          </div>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default SectionTwo;
