import { Row, Col } from "antd";
import topWave from "../../../../statics/assets/Frames/why-it-works/section3Top.svg";
import reward from "../../../../statics/assets/Frames/why-it-works/rewards.svg";
import PaperHeader from "../../../paper-header";
import WavesFrame from "../../../waveFrame";

const SectionThree = () => {
  return (
    <WavesFrame BgColor="#F9E8EB" topImage={topWave} topImageColor="#F9E8EB">
      <Row gutter={[0, 24]} className="section-two-main app-padding" >
        <Col xs={{ span: 24 }} lg={{ span: 16, offset: 4 }}>
          <div className="section-three-display mt-5">
            <img src={reward} alt="Epik Gift Card" height="180" />
            <div className="d-flex flex-column">
              <PaperHeader
                title={
                  "Reward Epik Efforts ™ While Keeping Kids and Teens Safe"
                }
                subTitle={
                  "The Epik Gift Card restricts use at the following types of stores:"
                }
                fontSize="text-62px"
                titleClass="bold mb-20"
                titleColor="#1B1B5B"
                subtitleClass="text-md mt-2"
              />
              <ul className="colored-li">
                <li>
                  <span className="green-dot"></span> Smoke Shops
                </li>
                <li>
                  <span className="green-dot"></span>Dispensaries
                </li>
                <li>
                  <span className="green-dot"></span>Liquor stores
                </li>
                <li>
                  <span className="green-dot"></span>And other sites and stores
                  that just don’t make sense.
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default SectionThree;
