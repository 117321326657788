import { FC, useEffect, useRef, useState } from "react";
import { Form, Checkbox, Col, Row, message, Modal } from "antd";

import FormButtons from "../form-buttons";
import { OrderSummaryInterface } from "../../../../interfaces/cardData";
import PaperHeader from "../../../paper-header";
import { EditOutlined } from "@mui/icons-material";
import { orderTotal } from "../orderSummary/orderSummary.utils";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../../app-store/user/user-reducer";
import moment from "moment";
import { checkoutApi } from "../../../../apis/auth_api";
import {
  convertStringToDate,
  showErrorNotification,
} from "../../../../utils/common-utils";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../../../credentials";

const ConfirmOrder: FC<OrderSummaryInterface> = ({
  next,
  prev,
  onCancel,
  data,
  confirmOrderData,
  onOrderNew,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const captchaRef = useRef<ReCAPTCHA>(null)

  useEffect(() => {
    localStorage.setItem("senderDetail", JSON.stringify(confirmOrderData));
  }, []);

  const anotherOrder = () => {
    onOrderNew?.("edit");
  };

  const calculateBulkQty = () => {
    return data.reduce(
      (n: any, { totalQty }: any) => +n + +totalQty,
      0
    )
  }

  const prepareData = () => {
    const {
      expDate,
      cardNumber,
      contactEmail,
      contactPhoneNumber,
      address1,
      city,
      state,
      zipCode,
      country,
      CVV
    } = confirmOrderData;

    const checkoutData = {
      currency: "USD",
      payment_info: {
        pan: cardNumber.replace(/ /g, ""),
        card_expiry: expDate,
        cvv: CVV
      },
      items: [] as Array<{}>,
      sender: {
        name: data[data.length - 1].senderName,
        email: contactEmail,
        country_dialing_code: "001",
        phone_no: contactPhoneNumber.replace(/\D/g, '').substring(0, 10) || "",
        address: {
          address1: address1,
          city: city,
          state: state,
          postal_code: zipCode,
          country: country,
        },
      },
    };

    data &&
      data.forEach((element: any) => {
        checkoutData.items.push({
          message: element?.message || "Gift Card",
          design: "WHITE-PLASTIC",
          quantity: element?.cardType === "bulk card" ? element?.totalQty : 1,
          denomination: element?.amount,
          card_type:
            element?.cardType === "digital card" ? "VIRTUAL" : "PHYSICAL",
          shipment_type: element?.shipmentType,
          delivery_date: moment(
            convertStringToDate(element?.deliveryDate)
          ).format("YYYY-MM-DD"),
          receiver: {
            name: element?.receiverName,
            email: element?.receiverEmail,
            country_dialing_code: "001",
            phone_no: "",
            address:
              element?.cardType !== "digital card"
                ? {
                    address1: element?.address1,
                    city: element?.city,
                    state: element?.state,
                    postal_code: element?.zipCode,
                    country: element?.country,
                  }
                : {
                    address1,
                    city,
                    state,
                    postal_code: zipCode,
                    country,
                  },
          },
        });
      });

    return checkoutData;
  };

  const onFinish = () => {
    if (recaptchaValue)
      setShowModal(true);
    else
      message.error('Captcha is required.');
  }

  const handleCaptcha = (response: any) => {
    setRecaptchaValue(response);
  };

  const onSubmitCheckout = () => {
    setShowModal(false);
    dispatch(setLoadingState(true));

  if (recaptchaValue) {
    checkoutApi({ captchaToken: recaptchaValue, ...prepareData()})
      .then((res) => {
        if (res?.status === 200) {
          if(res?.data?.status === 'PAYMENT_PENDING' && res.data.redirectUrl) {
            window.open(res.data.redirectUrl, '_self');
          } else {
            next(res?.data);
            dispatch(setLoadingState(false));
          }
        } else {
          message.error(res?.message);
          dispatch(setLoadingState(false));
        }
      })
      .catch((error) => {
        showErrorNotification(error);
        dispatch(setLoadingState(false));
      });
  }
  };

  return (
    <div className="step-form-fields confirm-order-form">
      <Form
        name="confirmForm"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
        requiredMark={false}
        className="mb-74"
      >
        <Row
          gutter={[0, 5]}
          align="middle"
          justify={"center"}
          className="form-row"
          id="epik-scroll"
        >
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <div className="d-flex justify-between align-end mb-20">
              <PaperHeader
                title="Order Summary"
                fontSize="text-lg"
                titleClass="weight-500 mb-0"
                titleColor="white"
              />
              <span
                style={{ color: "white", cursor: "pointer" }}
                onClick={anotherOrder}
              >
                <EditOutlined />
              </span>
            </div>
            <div className="summaryInfoCard">
              <span className="d-flex justify-between flex-wrap">
                <PaperHeader
                  title="Total Cards"
                  fontSize="text-md"
                  titleColor="black"
                  titleClass="weight-400"
                />
                <PaperHeader
                  title={
                    data[0]?.cardType === "bulk card"
                      ? calculateBulkQty()
                      : data?.length
                  }
                  fontSize="text-md"
                  titleColor="black"
                  titleClass="weight-500"
                />
              </span>
              <span className="d-flex justify-between flex-wrap">
                <PaperHeader
                  title="Order Total"
                  fontSize="text-lg"
                  titleColor="black"
                  titleClass="weight-500 mb-0"
                />
                <PaperHeader
                  title={`${orderTotal(data)}`}
                  fontSize="text-lg"
                  titleColor="black"
                  titleClass="extraBold mb-0"
                />
              </span>
            </div>
            <div className="agreement">
              <Form.Item name="agreement" valuePropName="checked">
                <Checkbox>
                  Please email me about future Epik Gift Card offers, knowing I can unsubscribe anytime.
                </Checkbox>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-center align-center mb-40">
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={SITE_KEY()}
            onChange={handleCaptcha}
          />
        </div>
        <FormButtons
          onCancel={onCancel}
          customClass={""}
          nextButtonText={"Continue"}
          cancelButtonText={"Cancel"}
          handlePrevious={prev}
        />
      </Form>

      <Modal
        centered
        closable={false}
        open={showModal}
        onOk={onSubmitCheckout}
        onCancel={() => setShowModal(false)}
        okText="Submit Order"
        cancelText="No"
        className="order-modal confirm-modal"
        width={600}
      >
        <div style={{ textAlign: "center" }}>
          <h2>Submit Order</h2>
          <p>Are you sure you want to submit this order?</p>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmOrder;
