import React from "react";
import { Row, Col } from "antd";

import wave from "../../../../statics/assets/Frames/why-it-works/Top.svg";
import frame from "../../../../statics/assets/Frames/why-it-works/Frame1.svg";

import "./landing.style.css";
import PaperHeader from "../../../paper-header";
import WavesFrame from "../../../waveFrame";

const LandinBanner = () => {
  return (
    <WavesFrame BgColor="#F4C3CC" bottomImage={wave} bottomImageColor="#FFFFFF">
      <div className="landing-main">
        <Row gutter={[0, 24]} className="mt-5 app-padding">
          <Col xs={{ span: 22 }} md={{ span: 10 }}>
            <div className="landing-header">
              <PaperHeader
                title={"Why it Works"}
                fontSize="text-lg"
                titleClass="bold link"
                titleColor="#CE0F69"
              />

              <PaperHeader
                title={"It’s Not Just a Gift Card, It’s a Reward"}
                fontSize="text-62px"
                titleClass="title bold mb-28"
                titleColor="#8D0043"
              />

              <PaperHeader
                title={
                  "The Epik Gift Card is a thoughtful gift specifically for kids and teens. We create a positive, family-friendly experience with easy physical or digital gift cards ranging from $10 - $500, redeemable virtually anywhere."
                }
                fontSize="text-md"
                titleClass="subtitle mb-0"
                titleColor="#8D0043"
              />
            </div>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 12, offset: 2 }}>
            <img src={frame} alt="Epik Gift Card" className="w-100" />
          </Col>
        </Row>
      </div>
    </WavesFrame>
  );
};

export default LandinBanner;
