import React from "react";
import {Col, Row} from "antd";
import "./style.css";
import WavesFrame from "../../waveFrame";
import PaperHeader from "../../paper-header";
import RegisterCardImage from "../../../statics/assets/Frames/Frame 227.png";
import CallIcon from "../../../statics/assets/Frames/call-icon.png";

const ActivateCard = () => {

  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="activate-card-main app-padding">
        <Col span={24}>
          <Row className="mb-25">
            <Col span={24}>
              <h1 className="primaryColor app-secondary-title extraBold mb-0">Activate Your Card</h1>
              <PaperHeader
                title={
                  <>
                    To activate and use your Physical Card, please dial:
                    <img src={CallIcon} alt="call-icon" className="call-icon"/>
                    <b> +1 844 404 0264.</b>
                  </>
                }
                fontSize="text-20"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 22}} md={{span: 10}}>
              <img src={RegisterCardImage} className="main-image" alt=""/>
            </Col>
          </Row>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default ActivateCard;
