import axiosAPI from "../../utils/axios";

export const checkBalance = async (reqBody: object) => {
  const response = await axiosAPI.post('check-balance/', { ...reqBody });
  return response.data;
};

export const contactUs = async (apiProps: object) => {
  const response = await axiosAPI.post(`contact-us`, { ...apiProps });
  return response.data;
};

export const verifyCardDetails = async (apiProps: object) => {
  const response = await axiosAPI.post(`cardRegistration/verifyCard`, { ...apiProps });
  return response.data;
};

export const registerCard = async (apiProps: any) => {
  const response = await axiosAPI.post(`cardRegistration/registerCard`, { ...apiProps });
  return response.data;
};

export const getShipments = async () => {
  const response = await axiosAPI.get(`shipment/types`);
  return response.data;
};

export const getCardFee = async () => {
  const response = await axiosAPI.get(`cards/types`);
  return response.data;
};

export const checkoutApi = async (apiProps: object) => {
  const response = await axiosAPI.post(`checkout`, { ...apiProps });
  return response.data;
};


export const getVirtualCardURL = async (apiProps: any) => {
  const response = await axiosAPI.post(`card-url`, { ...apiProps });
  return response.data;
};

export const changePassword = async (apiProps: any) => {
  const { old_password, new_password, token } = apiProps;
  return await axiosAPI.put(
    "/auth/password",
    { old_password, new_password },
    {
      Authorization: `Bearer ${token}`,
    }
  );
};
