import React, { FC, useState, useEffect } from "react";
import { Steps, Row, Col } from "antd";

import ContactInfo from "./ContactInfo";
import CardInfo from "./CardInfo";
import BillingInfo from "./BillingInfo";
import ConfirmOrder from "./ConfirmOrder";
import { OrderSummaryInterface } from "../../../../interfaces/cardData";

import WavesFrame from "../../../waveFrame";
import frameBG from "../../../../statics/assets/Frames/orderFrame.png";
import "./orderInformation.style.css";
import ThankYou from "./thankYou";

const { Step } = Steps;

const OrderInformationProcess: FC<OrderSummaryInterface> = ({
  prev,
  onCancel,
  data,
  onOrderNew
}) => {
  let orderInfoCurrentStep = 0;
  const storageCreatedOrderCode = localStorage.getItem("createdOrderCode")!;
  if(storageCreatedOrderCode?.length > 0) {
    orderInfoCurrentStep = +localStorage.getItem("confirmationStep")!
  }
  const [current, setCurrent] = useState(orderInfoCurrentStep);
  const [confirmOrderData, setconfirmOrderData] = useState( JSON.parse(localStorage.getItem("senderDetail")!) || {});
  const [trigger, setTrigger] = useState(0);

  const onChangeSteps = (value: any) => {
    if (value < current ) {
      setTrigger(0);
      setCurrent(value);
    } 
    else {
      setTrigger((prev) => prev + 1);
    }
  };

  const next = async (data?: any) => {
    setconfirmOrderData((prev: any) => ({ ...prev, ...data }));
    await handleNextStep();
  };

  const handleNextStep = async () => {
    setCurrent((prev) =>  prev + 1);
    setTrigger(0);
  };

  const previous = (data?: any) => {
    setTrigger(0);
    if (current === 0) return prev();
    // setconfirmOrderData((prev) => ({ ...prev, ...data }));
    setCurrent((prev) => prev - 1);
  };

  const anotherOrder = (type?: any, retian?: any) =>{
    type ? setCurrent(0) : onOrderNew?.(retian);
  }

  const formProps = {
    trigger,
    current,
    data,
    confirmOrderData,
    onCancel,
    next,
    prev: previous,
    onOrderNew: anotherOrder
  };

  return (
    <>
      {current === 4 ? (
        <ThankYou {...formProps} />
      ) : (
        <WavesFrame className="w-100 h-100" BgColor="#ffffff">
          <WavesFrame className="epik-card  order-BGframe" BgImage={frameBG}>
            <WavesFrame className="checkout board" BgColor="#F26F4C">
              <div className="board-content">
                <Row gutter={[0, 30]}>
                  <Col span="24">
                    <div className="card-order-sec epik-steps-content">
                      <Steps
                        type="navigation"
                        current={current}
                        onChange={onChangeSteps}
                        className="site-navigation-steps"
                      >
                        <Step title="Contact Info" />
                        <Step title="Card Info" />
                        <Step title="Billing Info" />
                        <Step title="Confirm Order" />
                      </Steps>

                      {current === 0 && <ContactInfo {...formProps} />}

                      {current === 1 && <CardInfo {...formProps} />}

                      {current === 2 && <BillingInfo {...formProps} />}

                      {current === 3 && <ConfirmOrder {...formProps} />}
                    </div>
                  </Col>
                </Row>
              </div>
            </WavesFrame>
          </WavesFrame>
        </WavesFrame>
      )}
    </>
  );
};

export default OrderInformationProcess;
