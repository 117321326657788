import React from "react";
// import Loader from "./components/shared/loader";
import "./App.css";

import AppContainer from "./views/AppContainer";
import { Spin } from "antd";
import { useSelector } from 'react-redux'
import { RootState } from "./app-store";
import { BASE_URL } from "./credentials";

const App = (): JSX.Element => {
  const {loading} = useSelector((state: RootState) => state.users)
  console.log('loading:', loading, process.env.NODE_ENV, BASE_URL())

  return (
    <Spin spinning={loading} size='large'>
      <AppContainer />
    </Spin>
  );
};

export default App;
