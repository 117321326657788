import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Steps, Row, Col, message, Modal } from "antd";
import { digitalFormSteps, physicalFormSteps } from "./steps.utils";
import { OrderCardLabels } from "../../../interfaces/cardData";
import OrderInformationProcess from "./OrderInformationProcess";
import OrderSummary from "./orderSummary";
import WavesFrame from "../../waveFrame";
import PaperHeader from "../../paper-header";

import epikBlack from "../../../statics/assets/whiteLogo.png";
import frameBG from "../../../statics/assets/Frames/orderFrame.png";
import ArrowBack from "../../../statics/assets/backArrow.svg";

import "./index.style.css";
import { getCardFee, getShipments } from "../../../apis/auth_api";
import { showErrorNotification } from "../../../utils/common-utils";
import { CloseCircleFilled } from "@ant-design/icons";

const { Step } = Steps;

const EpikCardSteps = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const title = new URLSearchParams(search).get("type")!;

  const [current, setCurrent] = useState(+localStorage.getItem("currentOrderStep")! || 0);
  const [cardItem, setCardItem] = useState({ cardType: title, senderName: "" });
  const [cardItemArr, setCardItemArr] = useState([]) as any[];
  const [editCardItem, setEditCardItem] = useState(null);

  const [windowWidth, setWindowWidth] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [isTypeChangedModalVisible, setIsTypeChangedModalVisible] = useState(false);
  const cardTypes = ['physical card', 'digital card', 'bulk card'];

  let existingOrderCardType: string = '';

  const openCheckOutScreenIfRequired = () => {
    if (existingOrderCardType?.length > 0) {
      setCurrent(title === "digital card" ? 4 : 5);
    }
  }

  const loadCardListFromStorage = () => {
    const storageCardsList = localStorage.getItem("cardsList");
    if (storageCardsList) {
      const cardList =  JSON.parse(storageCardsList);
      console.log(cardList)
      if(cardList?.length > 0){
        existingOrderCardType = cardList[0].cardType;
        setCardItemArr(cardList);
      }
    }
  }

  const handleInvalidCardType = () => {
    const isValidCardType = cardTypes.indexOf(title) !== -1;
    if(isValidCardType){
      if(existingOrderCardType !== '' && existingOrderCardType !== title) {
        setIsTypeChangedModalVisible(true);
      } else {
        openCheckOutScreenIfRequired();
      }
    } else {
      console.log(existingOrderCardType);
      if(existingOrderCardType !== '') {
        navigate("/order-epik-card?type=" + existingOrderCardType);
        openCheckOutScreenIfRequired();
      } else {
        navigate("/order-epik-card?type=physical card");
      }
    }
  }

  useEffect(() => {
    localStorage.setItem("orderCardType", title);
    localStorage.setItem("currentOrderStep", "0");
    getShipmentTypes();
    getCardFees();
    setWindowWidth(window.innerWidth);
    loadCardListFromStorage();
    handleInvalidCardType();
  }, []);

  const handleChangeCardType = () => {
    localStorage.removeItem("cardsList");
    localStorage.removeItem("senderDetail");
    setIsTypeChangedModalVisible(false);
    navigate("/order-epik-card?type=" + existingOrderCardType)
  };

  useEffect(() => {
    if (trigger !== 0) handleNextStep();
  }, [cardItem]);

  const onChangeSteps = (value: any) => {
    if (value < current) {
      setTrigger(0);
      setCurrent(value);
    } else {
      setTrigger((prev) => prev + 1);
    }
  };

  const next = (data?: any) => {
    data ? setCardItem((prev) => ({ ...prev, ...data })) : handleNextStep();
  };

  const handleNextStep = () => {
    console.log("card item", cardItem);
    if (title === "digital card" ? current === 3 : current === 4) {
      editCardItem !== null
        ? setCardItemArr(onUpdateCard())
        : setCardItemArr([...cardItemArr, ...[cardItem]]);
    }
    setCurrent((prev) => prev + 1);
    setTrigger(0);
  };

  const prev = (index?: any, step = false) => {
    if (!isNaN(index)) {
      setEditCardItem(index);
      setCardItem(cardItemArr[index]);
    }
    if (isNaN(index) && current === 0) navigate("/");
    setCurrent((prev) => (step ? 0 : prev - 1));
  };

  const onCancel = () => {
    setModalShow(true);
  };

  const onUpdateCard = () =>
    cardItemArr.map((obj: any, index: any) =>
      index === editCardItem ? cardItem : obj
    );

  const onOrderNew = (retian = true) => {
    setCurrent(retian ? 1 : 0);
    setCardItem({
      cardType: title,
      senderName: retian ? cardItemArr[0]?.senderName : "",
    });
    if (!retian) setCardItemArr([]);
    setEditCardItem(null);
  };

  const onRemoveCard = (index: any) => {
    const cardListArray = [...cardItemArr];
    const afterRemoveCard = [...cardListArray.splice(index, 1)];
    setCardItemArr(cardListArray);
    localStorage.setItem("cardsList", JSON.stringify(cardListArray));
    if (cardListArray.length === 0) onOrderNew();
  };

  const getShipmentTypes = () => {
    getShipments()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("shipmentFee", JSON.stringify(res?.data));
        } else {
          message.error(res?.message);
        }
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const getCardFees = () => {
    getCardFee()
      .then((res) => {
        if (res?.status === 200) {
          localStorage.setItem("cardFee", JSON.stringify(res?.data));
        } else {
          message.error(res?.message);
        }
      })
      .catch((error) => {
        showErrorNotification(error);
      });
  };

  const stepsLabels = OrderCardLabels(title);

  const getCurrentFormDetails = () => {
    return title === "digital card"
      ? digitalFormSteps[current]
      : physicalFormSteps[current];
  };

  const { component, listStep } = getCurrentFormDetails();

  const formProps = {
    next,
    current,
    trigger,
    data: cardItem,
    listStep,
  };

  const checkoutFormProps = {
    next,
    prev,
    current,
    trigger,
    data: cardItemArr,
    onCancel,
    onOrderNew,
    onRemoveCard,
  };

  return (
    <>
      {(title === "digital card" && current === 4) ||
      (title !== "digital card" && current === 5) ? (
        <OrderSummary {...checkoutFormProps} />
      ) : (title === "digital card" && current === 5) ||
        (title !== "digital card" && current === 6) ? (
        <OrderInformationProcess {...checkoutFormProps} />
      ) : (
        <WavesFrame className="epik-container w-100 h-100" BgColor="#BABAEA">
          <Row>
            <Col span="24">
              <WavesFrame className="epik-card" BgImage={frameBG}>
                <WavesFrame className="board" BgColor="#F26F4C">
                  <>
                    <div className="board-content">
                      <div className="d-flex align-center header">
                        <span className="steps-nav-sec">
                          <PaperHeader
                            title={`${current + 1} of ${stepsLabels.length}`}
                            fontSize="text-md"
                            titleClass="weight-500 mb-0"
                            titleColor="white"
                          />
                          <img
                            className="mb-40"
                            src={epikBlack}
                            alt="epik logo"
                          />
                        </span>

                        <h2 className="weight-600  mb-0">{title}</h2>
                      </div>
                      <div className="epik-step-box">
                        <div className="steps-nav-sec">
                          <Steps
                            progressDot
                            current={current}
                            onChange={onChangeSteps}
                            // onChange={() => setTrigger((prev) => prev+1)}
                            direction={
                              windowWidth > "768" ? "vertical" : "horizontal"
                            }
                          >
                            {stepsLabels &&
                              stepsLabels.map(({ title }, index) => (
                                <Step key={index} title={title} />
                              ))}
                          </Steps>
                        </div>

                        <div className="epik-step-form" id="epik-scroll">
                          <div className="epik-steps-content">
                            <div className="step-form-field">
                              {component(formProps)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="footer">
                        <span className="pointer" onClick={prev}>
                          <img src={ArrowBack} alt="Arrow" />
                        </span>
                        <button
                          id="submitBtn"
                          className="pointer extraBold"
                          onClick={() => setTrigger((prev) => prev + 1)}
                          type="submit"
                        >
                          {" "}
                          Continue
                        </button>
                        <span
                          className="weight-500 pointer cancelBtn"
                          onClick={onCancel}
                        >
                          Cancel
                        </span>
                      </div>

                      <PaperHeader
                        title={
                          title === "physical card"
                            ? " A physical gift card will be mailed to you or the recipient within X days. You may also choose a specific delivery date; the card will arrive within 2 – 3 days of the desired date."
                            : title === "digital card"
                            ? "You will receive an email confirmation with instructions to access the digital card usable for e-commerce transactions."
                            : <p>This is for schools, businesses, or institutions wishing to bulk purchase Epik Gift Cards. The maximum order size is 50 cards. For larger orders, please email us at 
                              (<a href="mailto:info@myepik.com" style={{color: 'inherit', textDecoration: 'underline'}} >info@myepik.com</a>).</p>
                        }
                        fontSize="text-base"
                        titleClass="weight-500"
                        titleColor="white"
                      />
                    </div>
                    {cardItemArr.length > 0 && (
                      <div
                        className="back-text pointer"
                        onClick={() =>
                          setCurrent(title === "digital card" ? 4 : 5)
                        }
                      >
                        <p>
                          <CloseCircleFilled /> Back to order summary
                        </p>
                      </div>
                    )}
                  </>
                </WavesFrame>
              </WavesFrame>
            </Col>
          </Row>
        </WavesFrame>
      )}

      <Modal
        centered
        closable={false}
        open={modalShow}
        onOk={() => {
          localStorage.clear();
          navigate("/");
        }}
        onCancel={() => setModalShow(false)}
        okText="Yes"
        cancelText="No"
        className="order-modal"
        width={600}
      >
        <div style={{ textAlign: "center" }}>
          <h2>Are you sure</h2>
          <p>
            Are you sure you want to cancel? If so, your information will not be saved.
          </p>
        </div>
      </Modal>
      <Modal
        centered
        open={isTypeChangedModalVisible}
        closable={false}
        onCancel={() => navigate("/")}
        onOk={() => handleChangeCardType()}
        okText="Yes"
        cancelText="No"
        className="order-modal"
        width={600}
      >
        <div style={{ textAlign: "center" }}>
          <h2>Are you sure</h2>
          <p>You already have {cardItemArr?.length> 0 && (cardItemArr[0]?.cardType)} order in your cart. If you continue, your existing order details will be lost.</p>
        </div>
      </Modal>
    </>
  );
};

export default EpikCardSteps;
