import React from "react";
import { Route, Routes, useLocation } from "react-router";
import EpikLandingPageOne from "../components/ContentArea/EpikLandingPageOne";
import WhyItWorksPage from "../components/ContentArea/WhyItWorks";
import WhoWeAre from "../components/ContentArea/WhoWeAre";
import ContactUs from "../components/ContentArea/contact-us";
import PrivacyPolicy from "../components/ContentArea/privacy-policy";
import FAQS from "../components/ContentArea/FAQS";
import Terms from "../components/ContentArea/Terms";
import PageNotFound from "./layouts/not-found";
import ThanksPage from "../components/ContentArea/contact-us/success-page";

import MainView from "./Main";
import RegisterCard from "../components/ContentArea/register-card";
import ActivateCard from "../components/ContentArea/activate-card";
const AuthWrapper = () => {
  const location = useLocation();

  return (
    <MainView routePath={location.pathname}>
      <Routes>
        <Route path="/" element={<EpikLandingPageOne />} />
        <Route path="why-it-works" element={<WhyItWorksPage />} />
        <Route path="who-we-are" element={<WhoWeAre />} />
        <Route path="register-card" element={<RegisterCard />} />
        <Route path="activate-card" element={<ActivateCard />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="FAQs" element={<FAQS />} />
        <Route path="terms" element={<Terms />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="thank-you" element={<ThanksPage />} />
      </Routes>
    </MainView>
    
  );
};

export default AuthWrapper;
