import React, { FC, useState } from "react";
import { Layout, Modal } from "antd";
import CardImg from "../../../../../statics/assets/cardImage.png";
import EditIcon from "../../../../../statics/assets/editIcon.svg";

import PaperHeader from "../../../../paper-header";
import { CloseCircleFilled } from "@ant-design/icons";
import { StateName } from "../../../../../utils/common-utils";
import {
  getCardFee,
  getSingleCardFee,
  getSipmentFee,
  singleCardTotal,
} from "../orderSummary.utils";
import { textTransform } from "@mui/system";

interface CardLayout {
  data?: any;
  editCardDetail: Function;
  onRemoveCard: Function;
}

const addressesInfo = (item: any) => {
  return (
    <>
      {item?.address1},{" "}
      {item?.address2 && (
        <>
          {item?.address2},{" "}
        </>
      )}
      {item?.city},
      {" "}
      {StateName(item?.state)} {item?.zipCode}
    </>
  );
};

const CardLayout: FC<CardLayout> = ({ data, editCardDetail, onRemoveCard }) => {
  const [selectedCard, setSelectedCard] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const onSelectCard = (index: any, type: string) => {
    type === "edit" ? setEditModal(true) : setDeleteModal(true);
    setSelectedCard(index);
  };

  const onDeleted = () => {
    onRemoveCard(selectedCard);
    setDeleteModal(false);
  };

  return (
    <div
      className="card_main d-flex flex-column"
      style={{ paddingRight: data.length > 1 ? "20px" : "0px" }}
    >
      {data &&
        data.map((item: any, index: any) => (
          <span className="order-card" key={index}>
            <Layout className="section1 align-center">
              <img src={CardImg} alt="epik card" />
              <div className="d-flex flex-column order-basic-info ">
                <span className="subtitle">
                  <b>FOR:</b> {item?.receiverName}{" "}
                </span>
                <span
                  className="subtitle"
                  style={{ textTransform: "capitalize" }}
                >
                  <b>CARD TYPE:</b> {item?.cardType}
                </span>
                {item?.cardType === "digital card" && 
                  <span className="subtitle email" title={item?.receiverEmail}>
                   <b>EMAIL:</b> {item?.receiverEmail}
                  </span>
                }
                {item?.cardType !== "digital card" && 
                  <span className="subtitle">
                  <b>ADDRESS:</b> {addressesInfo(item)}
                  </span>
                }
                <span className="subtitle">
                <b>DELIVERY DATE:</b> {item?.deliveryDate}
              </span>
              </div>
              
            </Layout>

            <Layout className="section2">
              <PaperHeader
                title="Card Value"
                fontSize="text-sm"
                titleClass="weight-500 mb-0"
                titleColor="black"
                subTitle={`$${
                  item?.cardType === "bulk card"
                    ? +item?.amount * +item?.totalQty
                    : +item?.amount
                }.00`}
                subtitleClass="bold mb-0"
                subTitleColor="black"
              />
              {/* {item?.cardType !== "digital card" && (
                <> */}
              <PaperHeader
                title="Card Fee"
                fontSize="text-sm"
                titleClass="weight-500 mb-0"
                titleColor="black"
                subTitle={`$${getSingleCardFee(item)}`}
                subtitleClass="bold mb-0"
                subTitleColor="black"
              />

              {/* <PaperHeader
                title="Shipment Fee"
                fontSize="text-sm"
                titleClass="weight-500 mb-0"
                titleColor="black"
                subTitle={`$${
                  item?.cardType === "digital card" ? 0 : getSipmentFee(item)
                }`}
                subtitleClass="bold mb-0"
                subTitleColor="black"
              /> */}

              <span className="verticalLine"></span>
              <PaperHeader
                headerClass="d-flex align-center "
                title={`Total Fee: ${" "}`}
                fontSize="text-md"
                titleClass="total-text weight-500 mb-0"
                titleColor="black"
                subTitle={`$${singleCardTotal(item)}`}
                subtitleClass="text-lg extraBold mb-0"
                subTitleColor="black"
              />
            </Layout>

            <div className="order-actions d-flex ">
              <img
                src={EditIcon}
                onClick={() => onSelectCard(index, "edit")}
                alt="edit icon"
              />
              <CloseCircleFilled
                onClick={() => onSelectCard(index, "delete")}
              />
            </div>
          </span>
        ))}

      <Modal
        centered
        closable={false}
        open={editModal}
        onOk={() => editCardDetail(selectedCard)}
        onCancel={() => setEditModal(false)}
        okText="Yes"
        cancelText="No"
        className="order-modal"
        width={600}
      >
        <div style={{ textAlign: "center" }}>
          <h2>Are you sure</h2>
          <p>You want to edit this selected card?</p>
        </div>
      </Modal>

      <Modal
        centered
        closable={false}
        open={deleteModal}
        onOk={onDeleted}
        onCancel={() => setDeleteModal(false)}
        okText="Yes"
        cancelText="No"
        className="order-modal"
        width={600}
      >
        <div style={{ textAlign: "center" }}>
          <h2>Are you sure</h2>
          <p>You want to delete this? It will permanently remove this card.</p>
        </div>
      </Modal>
    </div>
  );
};

export default CardLayout;
