import React from "react";
import { FC } from "react";
import useStyles from "../../hooks/use-styles";

interface PaperHeaderProps {
  title: any;
  subTitle?: string;
  headerClass?: string;
  titleClass?: string;
  subtitleClass?: string;
  fontWeight?: string;
  fontSize?: string;
  titleColor?: string;
  subTitleColor?: string;
}

const PaperHeader: FC<PaperHeaderProps> = (props) => {
  const {
    headerClass,
    title,
    subTitle,
    titleClass,
    subtitleClass,
    fontWeight,
    fontSize,
    titleColor,
    subTitleColor,
  } = props;

  const { primaryLabelColor } = useStyles(["primaryLabelColor"]);

  return (
    <div className={headerClass!}>
      <p
        className={`${
          fontSize ? fontSize : "text-base"
        } ${fontWeight} ${titleClass!}`}
        style={{ color: titleColor ? titleColor : primaryLabelColor }}
      >
        {title}
      </p>

      {subTitle && (
        <p
          className={`text-base ${subtitleClass!}`}
          style={{ color: subTitleColor ? subTitleColor : primaryLabelColor }}
        >
          {subTitle}
        </p>
      )}
    </div>
  );
};

export default PaperHeader;
