import React from "react";
import { Col, Row } from "antd";

import "./style.css";
import WavesFrame from "../../waveFrame";
import PaperHeader from "../../paper-header";
import ContactForm from "./contact-form";
import ContactUsImage from "../../../statics/assets/Frames/Frame 169.png";

const ContactUs = () => {
  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="contactMain app-padding">
        <Col xs={{ span: 22 }} md={{ span: 10 }}>
            <h1 className="primaryColor app-secondary-title bold">👋 Hello</h1>
            <PaperHeader
              title={<a href="mailto:info@myepik.com" >info@myepik.com</a>}
              fontSize="text-md"
              titleClass="bold mb-12"
            />
            <PaperHeader
              title={"+1 844 404 0264"}
              fontSize="text-md"
              titleClass="bold mb-12"
            />
            <PaperHeader
              title={"3641 Mt Diablo Blvd, #166 Lafayette CA 94549"}
              titleClass="mb-12"
            />
            {/* <div className="w-100">
             <ContactUsImage />
            </div> */}
            <img src={ContactUsImage} alt=""/>
        </Col>

        <Col xs={{ span: 22 }} md={{ span: 12, offset: 2 }}>
          <ContactForm />
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default ContactUs;
