import React from "react";
import { Col, Row } from "antd";

import WavesFrame from "../../waveFrame";
import PaperHeader from "../../paper-header";

const FAQS = () => {
  const categoriesList = [
    "OFFICE AND COMMERCIAL FURNITURE",
    "COMPUTERS/PERIPHERAL EQUIP, SOFTWARE",
    "MEN, WOMEN, CHILD UNIFORMS/COMM CLOTHING",
    "COMMERCIAL FOOTWEAR",
    "BOOKS, PERIODICALS, NEWSPAPERS",
    "FLORIST SUPPLIES, NURSERY STOCK, FLOWERS",
    "NURSERIES, LAWN & GARDEN SUPPLY STORES",
    "E-COMMERCE MARKETPLACE",
    "DUTY FREE STORES",
    "DISCOUNT STORES",
    "DEPARTMENT STORES",
    "MISC GENERAL MERCHANDISE",
    "GROCERY STORES AND SUPERMARKETS",
    "CANDY, NUT, CONFECTIONARY STORES",
    "DAIRY PRODUCTS STORES",
    "BAKERIES",
    "MISC FOOD STORES",
    "CHILDREN'S AND INFANT'S WEARSTORES",
    "FAMILY CLOTHING STORES",
    "SPORTS APPAREL, RIDING",
    "SHOE STORES",
    "FURRIERS AND FUR SHOPS",
    "MUSIC STORES - INSTRUMENTS, PIANOS, MUSIC",
    "COMPUTER SOFTWARE STORES",
    "RECORD STORES",
    "EATING PLACES AND RESTAURANTS",
    "FAST FOOD RESTAURANTS",
    "DIGITAL GOODS: BOOKS, MOVIES, MUSIC",
    "DIGITAL GOODS: GAMES",
    "DIGITAL GOODS: APPLICATIONS (EXCLUDES GAMES)",
    "DIGITAL GOODS: MULTI- CATEGORY",
    "BICYCLE SHOPS-SALES/SVC",
    "SPORTING GOODS STORES",
    "BOOK STORES",
    "STATIONARY/OFFICE/SCHOOL SUPPLY STORES",
    "HOBBY, TOY & GAME SHOPS",
    "CAMERA & PHOTOGRAPHIC SUPPLY STORES",
    "GIFT, CARD, SOUVENIR, NOVELTY SHOPS",
    "CLOTHING RENTAL-COSTUMES, UNIFORMS, FORM",
    "MOTION PICTURE THEATERS",
    "DVD/VIDEO RENTAL STORES",
    "BOWLING ALLEYS",
    "COMMERCIAL SPORTS, PRO SPORTS CLUBS, ATHLETIC FIELDS AND SPORTS PROMOTERS",
    "TOURIST ATTRACTIONS & EXHIBITS",
    "GOLF COURSES-PUBLIC",
    "VIDEO AMUSEMENT GAME SUPPLIES",
    "VIDEO GAME ARCADES / ESTABLISHMENTS",
    "AMUSEMENT PARKS, CIRCUSES, FORTUNE TELL",
    "MEMBERSHIP CLUBS (SPORTS, RECREATION, ATHLETIC), COUNTRY CLUBS AND PRIVATE GOLF COURSES",
    "AQUARIUMS",
    "RECREATION SERVICES (NOT CLASSIFIED)",
    "VOCATIONAL/TRADE SCHOOLS",
    "SCHOOLS/EDUC SVCS NOT CLASSIFIED",
    "PHOTOGRAPHIC, PHOTOCOPY, MICROFLM EQUIP.",
    "STATIONERY, OFFICE SUPPL, PRINT / WRITING",
    "MISC APPAREL & ACCESSORY SHOPS",
    "MISC & SPECIALTY RETAIL SHOPS",
    "WOMEN'S ACCESSORY & SPECIALTY STORES",
    "MENS/WOMENS CLOTHING STORES",
    "WOMEN'S READY-TO-WEAR",
    "MENS/BOYS CLOTHING AND ACCSRY STORE",
    "HARDWARE EQUIPMENT AND SUPPLIES",
    "ELECTRONIC STORES",
    "ARTISTS SUPPLY AND CRAFT STORES",
    "DANCE HALLS/STUDIOS/SCHOOLS",
    "SERVICE STATIONS",
  ];

  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="contactMain app-padding">
        <Col md={{ span: 24 }} lg={{ span: 20, offset: 2 }}>
          <PaperHeader
            title={"FAQs"}
            fontSize="app-secondary-title"
            titleClass="extraBold mb-40"
          />

          <PaperHeader
            title={`How can I purchase an Epik card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="The Epik gift card can be purchased from the official website www.epikcard.com."
          />
          <PaperHeader
            title={`Who can purchase the Epik card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="Anyone can purchase an Epik gift card by visiting www.epikcard.com. The Epik Card is primarily intended for kids and teenagers, but it is not limited to that age group. The card can be used by parents, siblings, grandparents, and others due to its wide acceptance at various merchant categories. For a comprehensive list of the specific merchant categories where the Epik Card can be used, please refer to the end of this FAQs."
          />
          <PaperHeader
            title={`What are the requirements to purchase the epik gift card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="The requirement for purchase the epik gift card is name, email address & phone number and a payment card for purchase."
          />
          <PaperHeader
            title={`Can I register my card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="To register your Epik Card, simply visit our website at www.epikcard.com. Begin by entering your card number to initiate the process. Then, provide your Name, Address, and phone number as requested. Registration is a requirement to be eligible for a replacement card in case the need arises."
          />
          <PaperHeader
            title={`Is there a fee to purchase the Epik card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="Yes, there is a fee of $ 5.95 to purchase the epik gift card."
          />
          <PaperHeader
            title={`What is the denomination range for Epik gift card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="Epik gift card offers one time loading option ranging from $10-$500 at the time of purchase."
          />
          <PaperHeader
            title={`Can I purchase the Epik card for a friend or family?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="Yes, you can purchase the card for friend and family as well by providing their name and email address. In case a physical card is requested then the recipient’s mailing address will also be required"
          />
          <PaperHeader
            title={`Can I apply for a secondary card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="No, Epik gift card does not offer this facility."
          />
          <PaperHeader
            title={`How can I check my available balance?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
          />
          <p className="text-base mb-25 primaryColor">
            You can check epik gift card available balance by visiting our
            official website www.epikcard.com or by calling at
            <b>1-844-404-0264.</b>
          </p>
          <PaperHeader
            title={`Where can I call for further information?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
          />
          <p className="text-base mb-25 primaryColor">
            You can call on customer services at <b>1-844-404-0264</b> for epik
            gift card information.
          </p>
          <PaperHeader
            title={`Can I ship the card anywhere in USA?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="Yes, The Epik gift card can be shipped anywhere within USA."
          />
          <PaperHeader
            title={`How long does it take to receive the card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="The virtual card can be made available immediately or any specific date of choice through the receiver’s email address, Plastic card takes 7-10 business days to get delivered at provided mailing address."
          />
          <PaperHeader
            title={`What is the shipping method?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="The Epik gift card currently offers regular mail (USPS) for card shipments."
          />
          <PaperHeader
            title={`What should I do if my phiscal card is lost or stolen?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
          />
          <p className="text-base mb-25 primaryColor">
            In case of plastic card lost/stolen, immediately call at{" "}
            <b>1-844-404-0264.</b> However, if you have not registered your card
            then you will not be able to get a replacement card.{" "}
          </p>
          <PaperHeader
            title={`I purchased the card for someone else and the recipient didn’t receive the email notification, what should I do?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
          />
          <p className="text-base mb-25 primaryColor">
            They can call our customer services at <b>1-844-404-0264.</b>
          </p>
          <PaperHeader
            title={`Is there a card replacement fee?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="Yes, card replacement fee is $5.95. However, if you have not registered your card then you are not eligible to get a replacement physical card."
          />
          <PaperHeader
            title={`Can I use the card at any store?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="The Epik gift card can be used for online and physical purchases at the virtually all merchants within USA where the Discover or Mastercard are accepted. Because it is designed for kids and teens, there are some merchants that are not appropriate. For a comprehensive list of the specific merchant categories where the Epik Card can be used, please refer to the end of this FAQs."
          />
          <PaperHeader
            title={`Can I use the card for online shopping?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="The Epik gift card can be used for online and physical purchases at the allowed merchants within USA where Discover or Mastercard are accepted. For a comprehensive list of the specific merchant categories where the Epik Card can be used, please refer to the end of this FAQs."
          />
          <PaperHeader
            title={`Can I use the card at an ATM?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="No, The Epik gift card does not offer this facility."
          />
          <PaperHeader
            title={`Can I reload Epik card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="No, The Epik gift card offer one time loading at the time of card purchase."
          />
          <PaperHeader
            title={`Can I transfer the available funds from Epik card to another card or a bank account?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="No, The Epik gift card does not offer this facility."
          />
          <PaperHeader
            title={`How do I close my Epik card?`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-40"
            subTitle="Please be advised that if you choose to close your Epik Card, any remaining balance on your gift card will be forfeited as of the closure date. We recommend that you use the remaining balance on your card before the closing the Epik card to avoid losing any funds."
          />

          <PaperHeader
            title={"EPIK Card Allowed Merchant Categories List MCC Description"}
            fontSize="text-2lg"
            titleClass="extraBold mb-25"
          />

          {categoriesList &&
            categoriesList.map((item) => (
              <PaperHeader
                title={item}
                fontSize="text-base weight-600"
                titleClass="mb-0"
                headerClass="mb-25"
              />
            ))}
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default FAQS;
