import React from "react";
import { Layout } from "antd";
import pinkLogo from "../../../statics/assets/primaryLogo.svg";
import './header.style.css';
import Navebar from "../Navebar";
import { Link } from "react-router-dom";
const Header = () => {
  const { Header } = Layout;

  return (
    <Header>
      <div className="header-left">
        <div className="gec-logo">
          <Link to={'/'} className='pointer'>
            <img src={pinkLogo} alt="Epik Card" />
          </Link>
        </div>
        {/* <div className="light-btn ">
          <Popover
            overlayClassName="banner-popup-two"
            title={false}
            trigger="hover"
            placement="bottom"
          >
            <Button className="ant-btn ant-btn-link" type="link">Give the Epik Card</Button>
          </Popover>
        </div> */}
      </div>
      <div className="header-right">
        <Navebar />
      </div>
    </Header>
  );
};

export default Header;
