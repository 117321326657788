import React, { FC, useEffect, useRef, useState } from "react";
import { Col, DatePicker, Form, Input, Row } from "antd";
import type { FormInstance } from "antd/es/form";
import "../style.css";
import CustomButton from "../../../custom-button";
import PaperHeader from "../../../paper-header";
import { verifyCardDetails } from "../../../../apis/auth_api";
import { formatCardNumber, getErrorMessage, validateZipCode } from "../../../../utils/common-utils";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../../../credentials";
import { RangePickerProps } from "antd/lib/date-picker";
import dayjs from 'dayjs';
import RegisterCardImage from "../../../../statics/assets/Frames/Frame 227.png";
import WavesFrame from "../../../waveFrame";
import { CardData, RegisterCardInterface } from "../../../../interfaces/cardData";
import { setLoadingState } from "../../../../app-store/user/user-reducer";
import { useDispatch } from "react-redux";

const tailLayout = {
  wrapperCol: { xs: { span: 24 }, lg: { span: 15 } },
};

const dateFormatList = ["YYYY-MM", "YY-MM"];

const VerifyCardForm: FC<RegisterCardInterface> = (
  {
    next,
    handleSetCardData,
  }
) => {
  const dispatch = useDispatch();
  const formRef = React.useRef<FormInstance>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [form] = Form.useForm();
  const [expiryDate, setExpiryDate] = useState<any>();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (captchaRef.current) {
      captchaRef.current.reset();
    }

    return () => {
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    }
  }, []);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handleChange = (event: any, type: string) => {
    let { value } = event.target;
    value = value.replace(/ /gi, "");
    if (isNaN(value)) {
      form?.setFieldsValue({
        [type]:
          type === "cardNumber"
            ? formatCardNumber(value.slice(0, -1))
            : value.slice(0, -1),
      });
      return;
    } else {
      form?.setFieldsValue({
        [type]: type === "cardNumber" ? formatCardNumber(value) : value,
      });
    }
  };

  const onDateChange = (date: any, dateString: string) => {
    setExpiryDate(dateString);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  useEffect(() => {
    document?.getElementById("cardNumber")?.focus();
  }, [])


  const handleCaptcha = (response: any) => {
    setRecaptchaValue(response);
  };


  const onFinish = (cardData: CardData) => {
    dispatch(setLoadingState(true));
    cardData.cardExpiry = expiryDate?.substring(5, 7) + '/' + expiryDate?.substring(2, 4);
    cardData.cardNumber = cardData.cardNumber?.replace(/ /gi, "");
    setShowError(false);
    if (recaptchaValue) {
      verifyCardDetails({ captchaToken: recaptchaValue, ...cardData})
        .then((res) => {
          if (res?.status === 200) {
            cardData.cardId = res.data;
            handleSetCardData(cardData);
            next();
            dispatch(setLoadingState(false));
          } else {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            dispatch(setLoadingState(false));
            setErrorMessage(res?.message);
            setShowError(true);
          }
        })
        .catch((error) => {
          // Handle error
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          setErrorMessage(getErrorMessage(error));
          setShowError(true);
          dispatch(setLoadingState(false));
        });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      setErrorMessage("reCAPTCHA validation required");
      setShowError(true);
    }
  };

  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row>
        <Col>
          {showError && errorMessage &&
            <Row justify="center" className="mb-12">
              <Col span={21} className="error-msg-wrapper">
                <PaperHeader
                  title={errorMessage}
                  fontSize="text-md error-msg-text"
                  titleClass="extraBold"
                />
              </Col>
            </Row>
          }
          <Row className="mb-25">
            <Col span={24}>
              <h1 className="primaryColor app-secondary-title extraBold mb-0">Verify Card</h1>
              <PaperHeader
                title={"Register your Physical Card to your name to avail the card replacement in case of any Fraud or Lost Stolen."}
                fontSize="text-20"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 22 }} md={{ span: 10 }}>
              <img src={RegisterCardImage} alt="" />
            </Col>

            <Col xs={{ span: 22 }} md={{ span: 10, offset: 4 }}>
              <Form
                // {...layout}
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                size="large"
                validateMessages={validateMessages}
                form={form}
                className="verify-card-form custom"
                autoComplete="off"
                requiredMark={false}
              >
                <Form.Item
                  name="cardNumber"
                  label="Card Number"
                  rules={[
                    { required: true },
                    {
                      max: 19,
                      message: "Card number is not valid",
                    },
                  ]}
                >
                  <Input
                    placeholder="0000 0000 0000 0000"
                    maxLength={19}
                    onChange={(event) => handleChange(event, "cardNumber")}
                  />
                </Form.Item>

                <Form.Item
                  name="cardExpiry"
                  label="Card Expiration Date"
                  rules={[{ required: true, message: "Please input date!" }]}
                  className="receiving-date"
                >
                  <DatePicker
                    placeholder="YYYY-MM"
                    format={dateFormatList}
                    suffixIcon={false}
                    bordered={false}
                    id="submitText"
                    style={{ width: "100%" }}
                    onChange={onDateChange}
                    picker="month" disabledDate={disabledDate}

                  />
                </Form.Item>
                <Form.Item
                  name={'zipCode'}
                  label={'Zip/Postal Code'}
                  rules={[{ required: true, message: 'This is a Required Field' },
                  { min: 5, max: 9, validator: validateZipCode, message: 'Zip Code must be a 5-9 digit number' },]}
                >
                  <Input placeholder={'Zip Code'} minLength={5} maxLength={9} />
                </Form.Item>
                <Form.Item>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={SITE_KEY()}
                    onChange={handleCaptcha}
                  />
                </Form.Item>
                <Form.Item>
                  <CustomButton
                    type="primary"
                    htmltype="submit"
                    text="Continue"
                    className="shadow-bottom continue-button mt-1-5"
                  ></CustomButton>
                </Form.Item>
                <Form.Item wrapperCol={{ ...tailLayout.wrapperCol }}>
                  <PaperHeader
                    title={
                      "This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply."
                    }
                    titleClass="mt-2"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default VerifyCardForm;
