import React from "react";
import { Row, Col } from "antd";


import frameBG1 from "../../../../statics/assets/Frames/section3Frame1.svg";
import frameBG2 from "../../../../statics/assets/Frames/section3Frame2.svg";
import topWave from "../../../../statics/assets/Frames/topWave.svg";
import bottomWave from "../../../../statics/assets/Frames/bottomGradientWave.svg";

import Image1 from "../../../../statics/assets/Frames/section3image1.png";
import {ReactComponent as Image2} from "../../../../statics/assets/Frames/section3image2.svg";

// import "./section3.style.css";
import CustomButton from "../../../custom-button";
import WavesFrame from "../../../waveFrame";
import PopoverButtton from "../../../popover-button";
import { useNavigate } from "react-router-dom";


const SectionThird = () => {
  const backgroundGradient = "linear-gradient(90deg, #FF6A39 0%, #9595D2 100%)";
  const navigate = useNavigate();

  return (
    <WavesFrame
      BgColor={backgroundGradient}
      topImage={topWave}
      topImageColor={backgroundGradient}
      bottomImage={bottomWave}
      bottomImageColor={"#F9E8EB"}
    >
      <div className="app-padding ">
        <Row gutter={[0, 24]} className="section3-main">
          <Col xs={{ span: 22 }} md={{ span: 12 }}>
            <div
              className="right-frame text-center right-border blue-border"
              style={{ backgroundImage: `url(${frameBG1})` }}
            >
              <div className="frame-inner-img">
                <div className="position-relative">
                  <img src={Image1} alt="Epik Gift Card" />
                  <div className="text">
                    <h4 className="title bold mb-0">Why it Works</h4>
                    <CustomButton
                      type="primary"
                      text="Learn More"
                      className="text-md shadow-bottom buttonLarge mt-1"
                      backgroundColor="white"
                      onClick={() => navigate('/why-it-works')}
                    ></CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 12 }}>
            <div
              className="right-frame text-center left-border blue-border"
              style={{ backgroundImage: `url(${frameBG2})` }}
            >
              <div className="frame-inner-img">
                <div className="position-relative">
                  <Image2 style={{ background: backgroundGradient }} />
{/* 
                  <img
                    src={Image2}
                    alt="Epik Gift Card"
                    style={{ background: backgroundGradient }}
                  /> */}
                  <div className="text">
                    <h4 className="title bold mb-0">Buy Now</h4>
                    <PopoverButtton className="text-md shadow-bottom buttonLarge mt-1" backgroundColor="white"/>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </WavesFrame>
  );
};

export default SectionThird;
