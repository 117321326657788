import React, { FC, useEffect, useState } from "react";
import { Col, Row } from "antd";
import CardLayout from "./cardLayout";
import SummaryLayout from "./summaryLayout";
import PaperHeader from "../../../paper-header";
import ArrowBack from "../../../../statics/assets/backArrow.svg";
import "./order-summary.style.css";
import WavesFrame from "../../../waveFrame";
import frameBG from "../../../../statics/assets/Frames/orderFrame.png";
import { OrderSummaryInterface } from "../../../../interfaces/cardData";

const OrderSummary: FC<OrderSummaryInterface> = ({
  next,
  prev,
  onCancel,
  onOrderNew,
  onRemoveCard,
  current,
  trigger,
  data,
}) => {
  const [selectedCard, setSelectedCard] = useState<number>();

  useEffect(() => {
    data && setSelectedCard(data.length - 1);
    localStorage.setItem("cardsList", JSON.stringify(data));
    if (trigger !== 0 && current === 5) {
    }
  }, [trigger]);

  return (
    // order_summary app-padding
    <WavesFrame className="w-100 h-100" BgColor="#BABAEA">
      <WavesFrame className="epik-card order-BGframe" BgImage={frameBG}>
        <WavesFrame className="board" BgColor="#F26F4C">
          <div className="board-content">
            <Row className="order-row" gutter={[0, 30]}>
              <Col xs={{ span: 24 }} xl={{ span: 16 }}>
                <div className="left-side" id="epik-scroll">
                  <PaperHeader
                    title="Added Cards"
                    fontSize="text-lg"
                    titleClass="weight-500 mb-15"
                    titleColor="white"
                  />
                  <CardLayout
                    data={data}
                    editCardDetail={(index: any) => prev(index, true)}
                    onRemoveCard={(index: any) => onRemoveCard?.(index)}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} xl={{ span: 7, offset: 1 }}>
                <div className="right-side">
                  <SummaryLayout data={data} OnNewOrder={() => onOrderNew?.()} />
                  <div className="footer">
                    <span
                      className="pointer"
                      onClick={() => prev(selectedCard)}
                    >
                      <img src={ArrowBack} alt="Arrow" />
                    </span>
                    <button
                      id="submitBtn"
                      className="pointer extraBold"
                      onClick={() => next()}
                      type="submit"
                    >
                      {" "}
                      Checkout
                    </button>
                    <span
                      className="weight-500 pointer cancelBtn"
                      onClick={() => onCancel?.()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </WavesFrame>
      </WavesFrame>
      {/* </Col>
      </Row> */}
    </WavesFrame>
  );
};

export default OrderSummary;
