const defaults = {
  // styles

  titleColor: "#05315F",
  subTitleColor: "#A7A7A7",
  labelColor: "#34495E",
  headerGreyColor: "#66788A",
  greyColor: "#7F8C8D",
  fontColor: "#0F3A5E",

  primaryLabelColor: "#1B1B5B",
  primaryButtonLabelColor: "#1B1B5B",
  primaryButtonBackground: "#B1DB42",
  primaryButtonBorder: "#1B1B5B",

  secondaryButtonLabelColor: "#31B4E7",
  secondaryButtonBackground: "#fff",
  secondaryButtonBorder: "#31B4E7",

  cardTextColor: "#FFFFFF",

  // configs
  cardRemitLogo: "",
  cardBackground: "https://cb-widget-assets.s3.amazonaws.com/my529bg.jpg",

  states:
    '[{"code":"AL","name":"Alabama"},{"code":"AK","name":"Alaska"},{"code":"AZ","name":"Arizona"},{"code":"AR","name":"Arkansas"},{"code":"CA","name":"California"},{"code":"CO","name":"Colorado"},{"code":"CT","name":"Connecticut"},{"code":"DE","name":"Delaware"},{"code":"FL","name":"Florida"},{"code":"GA","name":"Georgia"},{"code":"HI","name":"Hawaii"},{"code":"ID","name":"Idaho"},{"code":"IL","name":"Illinois"},{"code":"IN","name":"Indiana"},{"code":"IA","name":"Iowa"},{"code":"KS","name":"Kansas"},{"code":"KY","name":"Kentucky"},{"code":"LA","name":"Louisiana"},{"code":"ME","name":"Maine"},{"code":"MD","name":"Maryland"},{"code":"MA","name":"Massachusetts"},{"code":"MI","name":"Michigan"},{"code":"MN","name":"Minnesota"},{"code":"MS","name":"Mississippi"},{"code":"MO","name":"Missouri"},{"code":"MT","name":"Montana"},{"code":"NE","name":"Nebraska"},{"code":"NV","name":"Nevada"},{"code":"NH","name":"New Hampshire"},{"code":"NJ","name":"New Jersey"},{"code":"NM","name":"New Mexico"},{"code":"NY","name":"New York"},{"code":"NC","name":"North Carolina"},{"code":"ND","name":"North Dakota"},{"code":"OH","name":"Ohio"},{"code":"OK","name":"Oklahoma"},{"code":"OR","name":"Oregon"},{"code":"PA","name":"Pennsylvania"},{"code":"RI","name":"Rhode Island"},{"code":"SC","name":"South Carolina"},{"code":"SD","name":"South Dakota"},{"code":"TN","name":"Tennessee"},{"code":"TX","name":"Texas"},{"code":"UT","name":"Utah"},{"code":"VT","name":"Vermont"},{"code":"VA","name":"Virginia"},{"code":"WA","name":"Washington"},{"code":"WV","name":"West Virginia"},{"code":"WI","name":"Wisconsin"},{"code":"WY","name":"Wyoming"}]',
  countries: '[{"code":"USA","name":"United States of America"}]',
  shipmentTypes:
    '[{"code": "1", "name": "Expediting Shipment   -  $10", "value": "10.00"}]',
  cardFees:
    '[{"name": "physical card", "value": "3.95"}, {"name": "digital card", "value": "2.95"}, {"name": "bulk card", "value": "3.95"}]',

  accountPrograms: '["EVO_GPR"]',
  defaultMainView: "login",

  dateRangeFormat: "YYYY-MM-DD",

  copyrightText: "2022 © Copyright Open CP. All Rights Reserved",

  //Needs to be added in errors
  GENERAL: "Something went wrong",
  REQUIRED: "Required!",
} as any;

export default defaults;
