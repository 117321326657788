import React from "react";
import'./waves.style.css';

interface WavesFrameProps {
  topImage?: any;
  topImageColor?: any;
  bottomImage?: any;
  bottomImageColor?: any;
  className?: string;
  BgColor?: string;
  BgImage?: any;
  children?: JSX.Element;
}

const WavesFrame = ({
  topImage,
  topImageColor,
  bottomImage,
  bottomImageColor,
  className,
  BgColor,
  BgImage,
  children,
}: WavesFrameProps) => {

  const divStyleColor = {
    background: BgColor ? BgColor : 'white'
  };

  const divStyleimage = {
    backgroundImage: BgImage ? `url(${BgImage})` : 'none'
  };

  return (
    <div
      className={`frameMain ${className!}`}
      style={BgColor ? divStyleColor : divStyleimage}
    >
      {topImage && <img src={topImage} className="topImg w-100" 
      style={{
        background: topImageColor ? topImageColor : "white",
      }} alt="background"/>}
      {bottomImage && <img src={bottomImage}  className="bottomImg w-100" 
      style={{
        background: bottomImageColor ? bottomImageColor : "white",
      }} alt="background"/>}
      {children && children}
    </div>

  );
};

export default WavesFrame;
