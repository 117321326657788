import React from "react";
// import CustomButton from "../../custom-button";
// import FlexButtons from "../../flex-button";
import ArrowBack from "../../../../statics/assets/backArrow.svg";

const FormButtons = (props: any) => {
  const {
    handleNext,
    handlePrevious,
    onCancel,
    customClass,
    nextButtonText,
    cancelButtonText,
  } = props;

  return (
    <div className={`footer ${customClass}`}>
      <span className="pointer" onClick={handlePrevious}>
        <img src={ArrowBack} alt="Arrow" />
      </span>
      <button
        className="pointer extraBold"
        onClick={handleNext}
        type="submit"
      >
        {" "}
        {nextButtonText}
      </button>
      <span className="weight-500 pointer cancelBtn" onClick={onCancel}>
        {cancelButtonText}
      </span>
    </div>
  );
};

export default FormButtons;
