import { Row, Col } from "antd";

import frame from "../../../../statics/assets/Frames/who-we-are/Frame2.svg";
import Icon1 from "../../../../statics/assets/Frames/who-we-are/Icons.svg";
import Icon2 from "../../../../statics/assets/Frames/who-we-are/Icons2.svg";

import PaperHeader from "../../../paper-header";
import WavesFrame from "../../../waveFrame";

const SectionTwo = () => {
  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="section-two-main app-padding">
        <Col xs={{ span: 22 }} md={{ span: 10 }}>
          <img className="mb-40" src={Icon1} alt="Icon" />
          <PaperHeader
            title={"Vision"}
            subTitle={`Discover and reward the next generation of leaders by celebrating their creativity and supporting their 
            instinct for inclusion, community, and greatness.`}
            fontSize="text-62px"
            titleClass="bold mb-20"
            titleColor="#CE0F69"
            subTitleColor="#1B1B5B"
            headerClass="mb-60"
          />

          <img className="mb-40" src={Icon2} alt="Icon" />
          <PaperHeader
            title={"Mission"}
            subTitle={`We want to encourage and reward kids and teens for being Epik!TM and celebrate their
            Epik EffortsTM. Being Epik!TM can involve acts of kindness such as volunteering,
            mentoring, sticking up for friends and siblings, and caring for the environment and
            pets. Epik EffortsTM can represent a variety of exceptional achievements, including
            academics, music, art, sports, and other areas of personal accomplishment.`}
            fontSize="text-62px"
            titleClass="bold mb-20"
            titleColor="#CE0F69"
            subTitleColor="#1B1B5B"
          />
        </Col>

        <Col xs={{ span: 22 }} md={{ span: 12, offset: 2 }}>
          <div className="d-flex justify-end">
            <img className="resp-img" src={frame} alt="Epik Gift Card" />
          </div>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default SectionTwo;
