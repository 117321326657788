import React, { useEffect, useState } from "react";
import { Col, message, Row } from "antd";

import "./style.css";
import WavesFrame from "../../waveFrame";
import PaperHeader from "../../paper-header";
import CustomButton from "../../custom-button";

import epikWhite from "../../../statics/assets/epik-black-logo.png";
import frameBG from "../../../statics/assets/Frames/Frame.svg";

import DiscoverLogo from "../../../statics/assets/discover-card.png";
import CardView from "../../CardView";
import { useDispatch } from "react-redux";
import { showErrorNotification } from "../../../utils/common-utils";
import { setLoadingState } from "../../../app-store/user/user-reducer";
import { getVirtualCardURL } from "../../../apis/auth_api";
import { useLocation } from "react-router-dom";

const EpikCard = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token")!;
  const dispatch = useDispatch();

  const [cardMask, setCardMask] = useState({
    remitLogo: epikWhite,
    security: "484",
    validTill: "06/16",
    discoverImage: DiscoverLogo,
    title: "Test Card",
    cardNumber: "5000 9860 8300 0202",
  });
  const [secureUrl, setSecureUrl] = useState("");

  useEffect(() => {
    getSecureUrl();

    return () => {
      setSecureUrl("");
    };
  }, []);

  const getSecureUrl = () => {
    dispatch(setLoadingState(true));

    getVirtualCardURL({ token: token })
      .then((res) => {
        if (res?.status === 200) {
          setSecureUrl(res?.data?.secure_url);
          dispatch(setLoadingState(false));
        } else {
          message.error(res?.message);
          dispatch(setLoadingState(false));
        }
      })
      .catch((error) => {
        showErrorNotification(error);
        dispatch(setLoadingState(false));
      });
  };

  return (
    <WavesFrame className="w-100 h-100" BgColor="#CE0F69">
      <Row className="epik-card-main">
        <Col span="24">
          <WavesFrame className="epik-inner" BgImage={frameBG}>
            <WavesFrame className="white-board" BgColor="#F26F4C">
              <div className="inner-content">
                <img src={epikWhite} alt="epik logo" />
                <PaperHeader
                  title={"Your Digital Gift Card"}
                  fontSize="subtitle"
                  titleClass="bold mb-0"
                  titleColor="black"
                />
                {secureUrl ? (
                  <div className="iframe-container">
                    <iframe
                      src={secureUrl}
                      title="Virtual Card"
                      className="modal-cardView"
                    ></iframe>
                  </div>
                ) : (
                  <></>
                )}
                {/* <CustomButton
                  type="primary"
                  text="Download Your Digital Card"
                  className="shadow-bottom button"
                  backgroundColor="#E1006A"
                ></CustomButton> */}
              </div>
            </WavesFrame>
          </WavesFrame>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default EpikCard;
