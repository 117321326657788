import { getLanguage } from "../utils/language-helper";
import { labels } from "../utils/metadata";
import defaults from "../utils/defaults";

const useLabels = (keys: Array<string>): any => {
  const languageCode = getLanguage();
  const labelsData = {} as any;

  const metadataLabels = labels();

  keys.forEach((key) => {
    const labelInfo = metadataLabels.find(
      (label: any) =>
        label.config_code === key &&
        (label.languageCode ? label.language_code === languageCode : true)
    );

    labelsData[key] = labelInfo ? labelInfo.config_value : defaults[key];
  });

  return labelsData;
};

export default useLabels;
