import React, {useEffect} from "react";
import {Col, message, Row} from "antd";
import "./style.css";
import WavesFrame from "../../waveFrame";
import {setLoadingState} from "../../../app-store/user/user-reducer";
import {useDispatch} from "react-redux";
import PaperHeader from "../../paper-header";
import { useNavigate, useSearchParams } from "react-router-dom";
import {getErrorMessage} from "../../../utils/common-utils";
import {confirmPayment} from "../../../apis/payments";

const ProcessPayment = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    processPayment();
  }, []);

  const resetSteps = () => {
    setCurrentOrderStep("");
    setConfirmationStep("");
  }

  const setCurrentOrderStep = (step: string) => {
    localStorage.setItem("currentOrderStep", step);
  }

  const setConfirmationStep = (step: string) => {
    localStorage.setItem("confirmationStep", step);
  }

  const routeToOrderCard = () => {
    const orderCardType =  localStorage.getItem("orderCardType")!;
    if(orderCardType?.length > 0) {
      if(orderCardType === 'digital card') {
        setCurrentOrderStep("5");
      } else {
        setCurrentOrderStep("6");
      }
      navigate("/order-epik-card?type="+ orderCardType);
    } else {
      navigate("/");
    }
  }

  const handleApiError = () => {
    const cardsList = localStorage.getItem("cardsList");
    if (cardsList && JSON.parse(cardsList).length > 0) {
      routeToOrderCard();
    } else {
      navigate("/");
    }
  }

  const handleApiSuccess = (orderCode: number) => {
    setConfirmationStep("4");
    localStorage.setItem("createdOrderCode", String(orderCode));
    routeToOrderCard();
  }

  const processPayment = () => {
    resetSteps();
    dispatch(setLoadingState(true))
    const paymentIntent = searchParams.get("payment_intent")!;
    if (paymentIntent) {
      confirmPayment(paymentIntent)
        .then((res) => {
          if (res?.status === 200) {
            handleApiSuccess(res?.data?.order_code);
            dispatch(setLoadingState(false));
          } else {
            handleApiError();
            dispatch(setLoadingState(false));
            message.error({
              content: "Error occurred",
              duration: 10
            });
          }
        })
        .catch((error) => {
          handleApiError();
          dispatch(setLoadingState(false));
          message.error({
            content: getErrorMessage(error),
            duration: 10
          });
        });
    } else {
      message.error({
        content: "Error occurred",
        duration: 10
      });
      navigate("/");
    }
  }

  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="process-payment-main app-padding" align="middle" justify="center">
        <Col>
          <Row align="middle" justify="center">
            <Col>
              <PaperHeader
                title="Processing Payment"
                fontSize="text-25"
                titleClass="extraBold mb-0"
              />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col>
              <PaperHeader
                title="Do not navgiate away from this screen"
                fontSize="text-md"
                titleClass="bold"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default ProcessPayment;
