export interface OrderCardData {
  address1?: string;
  address2?: string;
  amount?: string;
  cardType?: string;
  city?: string;
  country?: string;
  deliveryDate?: string;
  message?: string;
  receiverEmail?: string;
  receiverName?: string;
  senderName?: string;
  shipmentType?: string;
  state?: string;
  zipCode?: string;
  totalQty?: number;
}

export interface orderBasinInterface {
  next: Function;
  current?: any;
  trigger?: number;
  data?: OrderCardData;
  listStep?: any;
}

export interface OrderSummaryInterface {
  next: Function;
  prev: Function;
  onCancel?: Function;
  onOrderNew?: Function;
  onRemoveCard?: Function;
  current?: any;
  trigger?: number;
  data?: any;
  confirmOrderData?: any;
}

export interface CardData {
  cardNumber?: string;
  cardExpiry?: string;
  zipcode?: string;
  cardId?: string;
}

export interface UserData {
  cardId?: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  address1: string;
  address2?: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
}

export interface RegisterCardInterface {
  next: () => void;
  prev: () => void;
  current?: any;
  cardData?: CardData;
  userData?: UserData;
  handleSetCardData: (cardData: CardData) => void;
  handleSetUserData: (userData: UserData) => void;
}


export const OrderCardLabels = (labels: string) => {
  const physicalCardLabels = [
    {
      title: "Sender Name",
    },
    {
      title: "Receiver Info",
    },
    {
      title: "Amount",
    },
    {
      title: "Address",
    },
    {
      title: "Shipment Date",
    },
  ];

  const digitalCardLabels = [
    {
      title: "Sender Name",
    },
    {
      title: "Receiver Info",
    },
    {
      title: "Amount",
    },
    {
      title: "Shipment Date",
    },
  ];

  return labels?.toLowerCase() === "digital card"
    ? digitalCardLabels
    : physicalCardLabels;
};

export interface ThankYouInterface {
  confirmOrderData?: any;
  data?: any;
  onOrderNew: Function;
}
