import React, { FC, useEffect } from "react";
import { Form, Input, Col, Row, Select, message } from "antd";

import FormButtons from "../form-buttons";
import { OrderSummaryInterface } from "../../../../interfaces/cardData";
import useConfigs from "../../../../hooks/use-config";
import { CaretDownOutlined } from "@ant-design/icons";
import { validateZipCode } from "../../../../utils/common-utils";

const { Option } = Select;

const BillingInfo: FC<OrderSummaryInterface> = ({
  next,
  prev,
  onCancel,
  current,
  trigger,
  data,
  confirmOrderData
}) => {
  const [form] = Form.useForm();
  const { states, countries } = useConfigs([
    "states", "countries"
  ]);

  const setFormData = () => {
    confirmOrderData && form.setFieldsValue(confirmOrderData);
  };

  useEffect(() => {
    document?.getElementById("address1")?.focus();
    setFormData();
  }, [])

  useEffect(() => {
    if (trigger !== 0 && current === 2) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = (values: any) => next(values);

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo?.errorFields[0]?.errors[0]);
  };


  const validateMessages = { required: "${label} is required!" };

  return (
    <div className="step-form-fields">
      <Form
        name="billingInfo"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        autoComplete="off"
        layout="vertical"
        form={form}
        requiredMark={false}
        className="confirmForm mb-74"
      >
        <Row gutter={[0, 10]} className="form-row" id="epik-scroll">
          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <Form.Item
              name="address1"
              label="Street Address"
              rules={[{ required: true, message: 'Address is required!' }]}
            >
              <Input id="address1" placeholder="Enter your address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 3 }}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true }]}
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <Form.Item name="address2" label="Apt, Suite, etc (Optional)">
              <Input placeholder="Example" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 3 }}>
            <Form.Item
              name={'zipCode'}
              label={'Zip Code'}
              rules={[{ required: true, message: 'This is a Required Field' },
              { min: 5, max: 9, validator: validateZipCode, message: 'Zip Code must be a 5-9 digit number' },]}
            >
              <Input placeholder={'Zip Code'} minLength={5} maxLength={9} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select State"
                placement="topLeft"
                style={{ width: "100%" }}
                showSearch
                suffixIcon={<CaretDownOutlined />}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(trigger) => trigger.parentElement}
                dropdownMatchSelectWidth={false}
              // dropdownAlign={{ offset: [0, 4] }}
              >
                {states && states.map((item: any, index: any) => (
                  <Option key={index} value={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 3 }}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true }]}
              initialValue={'USA'}
            >
              <Select
                placeholder="Select Your Country"
                placement="bottomLeft"
                style={{ width: "100%" }}
                showSearch
                suffixIcon={<CaretDownOutlined />}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(trigger) => trigger.parentElement}
                dropdownMatchSelectWidth={false}
              // dropdownAlign={{ offset: [0, 4] }}
              >
                {countries && countries.map((item: any, index: any) => (
                  <Option key={index} value={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

        </Row>

        <FormButtons
          onCancel={onCancel}
          customClass={"confirmButtons"}
          nextButtonText={"Continue"}
          cancelButtonText={"Cancel"}
          handlePrevious={prev}
        />
      </Form>
    </div>
  );
};

export default BillingInfo;
