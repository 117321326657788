import { Layout } from "antd";

import Header from "../layouts/header";
import Footer from "../footer";

import "./main.style.css";
import { useEffect } from 'react';
import { unstable_HistoryRouter } from "react-router-dom";

const { Content } = Layout;

const MainView = (props: { children?: JSX.Element, routePath: any }) => {
  useEffect(() => {
      window.scrollTo(0, 0);
  });

  return (
    <div className="epik-landing-one">
      <Layout className="layout">
        <Header />
        <Content className="site-layout-background">
          {props.children && props.children}
        </Content>
        <Footer path={props.routePath} />
      </Layout>
    </div>
  );
};

export default MainView;
