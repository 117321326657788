import React, {useEffect, useState, useRef} from "react";
import { message, Form, Input } from "antd";
import type { FormInstance } from "antd/es/form";
import "../style.css";
import CustomButton from "../../../custom-button";
import PaperHeader from "../../../paper-header";
import { contactUs } from "../../../../apis/auth_api";
import { showErrorNotification } from "../../../../utils/common-utils";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../../../credentials";

const tailLayout = {
  wrapperCol: { xs: { span: 24 }, lg: { span: 15 } },
};

const ContactForm: React.FC = () => {
  const navigate = useNavigate();
  const formRef = React.useRef<FormInstance>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  useEffect(() => {
    if (captchaRef.current) {
      captchaRef.current.reset();
    }

    return () => {
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    }
  }, []);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handleCaptcha = (response: any) => {
    setRecaptchaValue(response);
  };

  
  const onFinish = (values: any) => {
    if (recaptchaValue) {
      contactUs({ captchaToken: recaptchaValue, ...values })
        .then((res) => {
          if (res?.status === 200) {
            navigate("/thank-you");
          } else {
            message.error(res?.message);
          }
        })
        .catch((error) => {
          // Handle error
          showErrorNotification(error);
        });
    } else {
      message.error("reCAPTCHA validation required");
    }

    onReset();
  };

  const onReset = () => {
    formRef.current?.resetFields();
  };

  return (
    <Form
      // {...layout}
      layout="vertical"
      ref={formRef}
      name="control-ref"
      onFinish={onFinish}
      size="large"
      validateMessages={validateMessages}
      className="contact-form custom"
      autoComplete="off"
      requiredMark={false}
    >
      <Form.Item
        name="name"
        label="Full Name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email Address"
        rules={[{ required: true, type: "email" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="phoneNo" label="Phone Number">
        <Input maxLength={20} />
      </Form.Item>
      <Form.Item name="message" label="Message">
        <Input.TextArea rows={4} showCount maxLength={1000} />
      </Form.Item>
      <Form.Item>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={SITE_KEY()}
          onChange={handleCaptcha}
        />
      </Form.Item>
      <Form.Item>
        <CustomButton
          type="primary"
          htmltype="submit"
          text="Send Message"
          className="shadow-bottom buttonLarge mt-1-5"
        ></CustomButton>
      </Form.Item>
      <Form.Item wrapperCol={{ ...tailLayout.wrapperCol }}>
        <PaperHeader
          title={
            "This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply."
          }
          titleClass="mt-2"
        />
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
