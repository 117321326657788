import React from "react";
import { Col, Row } from "antd";

import SuccessImg from "../../../../statics/assets/Frames/contact-success.png";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../custom-button";
import PaperHeader from "../../../paper-header";
import WavesFrame from "../../../waveFrame";



const ThanksPage = () => {
  const navigate = useNavigate();
  const backToHome = () =>{
    navigate('/')
  }
  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 50]} className="contactMain app-padding" >
        <Col xs={{ span: 22 }} md={{ span: 10 }} lg={{ span: 7 }} className="d-flex flex-column justify-center">
          <h1 className="primaryColor app-secondary-title extraBold mb-25">
          👍 Sent
          </h1>
          <PaperHeader
            title={
              "Thank you for filling out the form. We'll be in touch with you as soon as possible. "
            }
            titleClass="mb-40"
            fontSize="text-md"
          />

          <CustomButton
            type="primary"
            text="Take Me Home"
            className="not-found text-md shadow-bottom buttonLarge"
            onClick={backToHome}
          ></CustomButton>
        </Col>

        <Col xs={{ span: 22 }} md={{ span: 10, offset:2}} lg={{ span: 8, offset: 5}}>
          <img src={SuccessImg} style={{margin: '0rem'}}  alt="success"/>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default ThanksPage;
