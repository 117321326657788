import React, { FC, useEffect } from "react";
import { Col, Form, Input, message, Row } from "antd";
import { orderBasinInterface } from "../../../../interfaces/cardData";

const SenderDetail: FC<orderBasinInterface> = ({ next, current, trigger, data, listStep }) => {
  const [form] = Form.useForm();

  const setFormData = () => {
    data && form.setFieldsValue(data);
  };

  useEffect(() => {
    setFormData();
    var input = document?.getElementById("senderName")?.focus();
    if (current === listStep) {
      return input;
    }
  }, [current]);

  // const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
  //   console.log(event.key);
  //   if (event.key === "Enter") {
  //     next();
  //   }
  // };

  useEffect(() => {
    if (trigger !== 0 && current === listStep) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = (values: any) => {
    next(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo?.errorFields[0]?.errors[0]);
  };

  return (
    <Form
      name="senderForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
      // style={{ maxWidth: 380 }}
      layout="vertical"
      requiredMark={false}
    >
      <div className="step-form-fields">
        <Row gutter={[0, 0]}>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <Form.Item
              name="senderName"
              label="Sender Name"
              rules={[{ required: true, message: "Sender Name is required!" }]}
            >
              <Input id="senderName" placeholder="Enter Name" maxLength={50} />
            </Form.Item>
          </Col>
          {/* <Col span={12}></Col> */}
        </Row>
      </div>
    </Form>
  );
};

export default SenderDetail;
