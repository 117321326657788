import React, { FC, useEffect } from "react";
import { Form, Input, message, Row, Col, Select } from "antd";
import "antd/dist/antd.css";
import useConfigs from "../../../../hooks/use-config";
import { CaretDownOutlined } from "@ant-design/icons";
import { orderBasinInterface } from "../../../../interfaces/cardData";
import { validateZipCode } from "../../../../utils/common-utils";

const { Option } = Select;

const AddressForm: FC<orderBasinInterface> = ({
  next,
  current,
  trigger,
  data,
  listStep,
}) => {
  const [form] = Form.useForm();
  const { states, countries } = useConfigs(["states", "countries"]);
  const setFormData = () => {
    data && form.setFieldsValue(data);
  };

  useEffect(() => {
    setFormData();
    var input = document?.getElementById("address1")?.focus();
    if (current === listStep) {
      return input;
    }
  }, [current]);

  // const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
  //   console.log(event.key);
  //   if (event.key === "Enter") {
  //     next();
  //   }
  // };

  useEffect(() => {
    if (trigger !== 0 && current === listStep) {
      form.submit();
    }
  }, [trigger]);

  const validateMessages = {
    required: "${name} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };

  const onFinish = (values?: any) => {
    next(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo?.errorFields[0]?.errors[0]);
  };

  return (
    <Form
      name="addressForm"
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
      form={form}
      layout="vertical"
      requiredMark={false}
    >
      <div className="step-form-fields">
        <Row gutter={[0, 10]}>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <Form.Item
              name="address1"
              label="Receiver Street Address"
              rules={[{ required: true, message: "Address is required!" }]}
            >
              <Input id="address1" placeholder="Enter Receiver address" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <Form.Item name="address2" label="Apt, Suite, etc (Optional)">
              <Input placeholder="Example" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[0, 10]}>
          <Col xs={{ span: 24 }} md={{ span: 7 }}>
            <Form.Item name="city" label="City" rules={[{ required: true }]}>
              <Input placeholder="City" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8, offset: 3 }}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true }]}
            // initialValue={''}
            >
              <Select
                placeholder="Select State"
                placement="topLeft"
                style={{ width: "100%" }}
                showSearch
                suffixIcon={<CaretDownOutlined />}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(trigger) => trigger.parentElement}
                dropdownMatchSelectWidth={false}
              // dropdownAlign={{ offset: [0, 4] }}
              >
                {states &&
                  states.map((item: any, index: any) => (
                    <Option key={index} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[0, 10]}>
          <Col xs={{ span: 24 }} md={{ span: 7 }}>
            <Form.Item
              name={'zipCode'}
              label={'Zip Code'}
              rules={[{ required: true, message: 'This is a Required Field' },
              { min: 5, max: 9, validator: validateZipCode, message: 'Zip Code must be a 5-9 digit number' },]}
            >
              <Input placeholder={'Zip Code'} minLength={5} maxLength={9} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8, offset: 3 }}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true }]}
              initialValue={"USA"}
            >
              <Select
                placeholder="Select Country"
                placement="bottomLeft"
                style={{ width: "100%" }}
                showSearch
                suffixIcon={<CaretDownOutlined />}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(trigger) => trigger.parentElement}
                dropdownMatchSelectWidth={false}
              // dropdownAlign={{ offset: [0, 4] }}
              >
                {countries &&
                  countries.map((item: any, index: any) => (
                    <Option key={index} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default AddressForm;
