import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import EpikLandingPageOne from "../components/ContentArea/EpikLandingPageOne";
import EpikCardSteps from "../components/ContentArea/EpikCardSteps";
import WhyItWorksPage from "../components/ContentArea/WhyItWorks";
import WhoWeAre from "../components/ContentArea/WhoWeAre";
import ContactUs from "../components/ContentArea/contact-us";

import EpikCard from "../components/ContentArea/epik-virtual-card";
import PrivacyPolicy from "../components/ContentArea/privacy-policy";
import FAQS from "../components/ContentArea/FAQS";
import Terms from "../components/ContentArea/Terms";
import PageNotFound from "./layouts/not-found";
import RegisterCard from "../components/ContentArea/register-card";
import ProcessPayment from "../components/ContentArea/process-payment";
import ActivateCard from "../components/ContentArea/activate-card";


const AppContainer = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AuthWrapper />}>
          <Route path="/" element={<EpikLandingPageOne />} />
          <Route path="why-it-works" element={<WhyItWorksPage />} />
          <Route path="who-we-are" element={<WhoWeAre />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="register-card" element={<RegisterCard />} />
          <Route path="activate-card" element={<ActivateCard />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="FAQs" element={<FAQS />} />
          <Route path="terms" element={<Terms />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="epik-card" element={<EpikCard />} />
        <Route path="/order-epik-card" element={<EpikCardSteps />} />
        <Route path="process-payment" element={<ProcessPayment />} />
      </Routes>
    </>
  );
};

export default AppContainer;
