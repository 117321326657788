import React, { FC, useEffect, useState } from "react";
import { Form, DatePicker, message, Row, Col, Select } from "antd";
import moment from "moment";
import { CaretDownOutlined } from "@ant-design/icons";
import { orderBasinInterface } from "../../../../interfaces/cardData";
import { getShipmentDays } from "../orderSummary/orderSummary.utils";

const dateFormatList = ["MM/DD/YYYY", "DD/MM/YY"];
const { Option } = Select;

const ShipmentDateForm: FC<orderBasinInterface> = ({
  next,
  current,
  trigger,
  data,
  listStep,
}) => {
  const [form] = Form.useForm();
  const shipmentTypes = JSON.parse(localStorage.getItem("shipmentFee") || "{}");
  const [deliveryDate, setDeliveryDate] = useState<any>();

  const setFormData = () => {
    data &&
      form.setFieldsValue({
        deliveryDate: data?.deliveryDate
          ? moment(data?.deliveryDate, dateFormatList[0])
          : null,
        shipmentType: data?.shipmentType || shipmentTypes[2].type,
      });
    setDeliveryDate(data?.deliveryDate);
  };

  useEffect(() => {
    setFormData();
    var input = document?.getElementById("submitText")?.focus();
    if (current === listStep) {
      return input;
    }
  }, [current]);

  useEffect(() => {
    if (trigger !== 0 && current === listStep) {
      form.submit();
    }
  }, [trigger]);

  const onChange = (date: any, dateString: any) => {
    setDeliveryDate(dateString);
  };

  const disabledDate = (current: any) => {
    if (data?.cardType === "digital card") {
      // Disable dates before the current date
      if (current && current < moment().startOf("day")) {
        return true;
      }
    } else {
      if (
        current.isBefore(
          moment().add(
            getShipmentDays(form?.getFieldValue("shipmentType")) - 1,
            "day"
          )
        )
      ) {
        return true;
      }
    }

    // Disable dates after 24 months from the current date
    const maxDate = moment().add(24, "months").endOf("day");
    if (current && current > maxDate) {
      return true;
    }

    return false;
  };

  const onFinish = (values: any) => {
    const expectedDays = moment().add(
      getShipmentDays(form?.getFieldValue("shipmentType")),
      "day"
    );
    if (data?.cardType !== "digital card") {
      if (
        moment(values.deliveryDate.format("ll")).isSameOrAfter(
          expectedDays.format("ll")
        )
      ) {
        values.deliveryDate = deliveryDate;
        next(values);
      } else {
        message.error({
          content: `Delivery Date for "${form?.getFieldValue(
            "shipmentType"
          )}" shipping must be "${moment(expectedDays).format(
            "ll"
          )}" or greater.`,
          duration: 3,
        });
      }
    } else {
      values.deliveryDate = deliveryDate;
      next(values);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo?.errorFields[0]?.errors[0]);
  };

  return (
    <Form
      name="addressForm"
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}
      form={form}
      layout="vertical"
      requiredMark={false}
    >
      <div className="step-form-fields">
        <Row gutter={[0, 10]}>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <Form.Item
              name="deliveryDate"
              label="When should it be sent?"
              rules={[{ required: true, message: "Please input date!" }]}
              className="receiving-date"
            >
              <DatePicker
                placeholder="00/00/0000"
                showToday={false}
                format={dateFormatList}
                bordered={false}
                id="submitText"
                style={{ width: "100%" }}
                onChange={onChange}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          {data && data.cardType !== "digital card" && (
            <Col xs={{ span: 24 }} md={{ span: 18 }}>
              <Form.Item
                name="shipmentType"
                label="Select Shipment Type"
                rules={[{ required: true }]}
                extra={data.cardType === "physical card"?
                  "Physical card will be delivered to the receiver/recipient within 2 – 3 business days of the desired date."
                  : data.cardType === "bulk card"?
                  "Physical cards will be delivered to the receiver/recipient within 2 – 3 business days of the desired date."
                  : ""
                }
              >
                <Select
                  placeholder="Select Type"
                  placement="bottomLeft"
                  style={{ width: "100%" }}
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={(trigger) => trigger.parentElement}
                  dropdownMatchSelectWidth={false}
                  // dropdownAlign={{ offset: [0, 4] }}
                >
                  {shipmentTypes &&
                    shipmentTypes.map((item: any, index: any) => (
                      <>
                        {item?.type === "STANDARD" && (
                          <Option key={index} value={item?.type}>
                            {item?.type}
                          </Option>
                        )}
                      </>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
      </div>
    </Form>
  );
};

export default ShipmentDateForm;
