import React from 'react'
import useStyles from '../../hooks/use-styles'

const PrimaryButton = (props: any) => {
  const { htmltype } = props
  const {
    primaryButtonLabelColor,
    primaryButtonBackground,
    primaryButtonBorder
  } = useStyles([
    'primaryButtonLabelColor',
    'primaryButtonBackground',
    'primaryButtonBorder'
  ])

  const buttonProps = {
    ...(htmltype && { htmltype:htmltype })
  }
  return (
    
    <button
      disabled={props.disabled}
      className={`custom-button ${props.className!}`}
      style={{
        backgroundColor: props.backgroundColor ? props.backgroundColor : primaryButtonBackground,
        border: `2px solid ${primaryButtonBorder}`,
        color: primaryButtonLabelColor,
        ...(props.style && props.style),
      }}
      onClick={props.onClick}
      {...buttonProps}
    >
      <span>{props.text}</span>
    </button>
  )
}

export default PrimaryButton
