import React, { useEffect, useState } from "react";
import {
  MenuOutlined,
  CloseOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

import { Drawer } from "antd";
import "./navStyle.style.css";
import CheckBalance from "../../../components/ContentArea/check-balance";
import PopoverButtton from "../../../components/popover-button";

const Navebar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [showCheckBalance, setShowCheckBalance] = useState(false);

  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState("");

  const styles = {
    body: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column" as "column",
    },
  };

  const URLS = {
    home: "/",
    whyItWorks: "/why-it-works",
    whoWeAre: "/who-we-are",
    checkBalance: "javascript:void(0);",
    registerCard: "/register-card",
    activateCard: "/activate-card",
    contact: "/contact-us",
  };

  const showDrawer = () => {
    setIsNavExpanded(!isNavExpanded);
    setShowCheckBalance(true);
  };

  const onClose = () => {
    setShowCheckBalance(false);
  };

  useEffect(() => setUrl(location.pathname), [location]);
  return (
    <div className="landing-navebar">
      <nav className="navigation">
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          {isNavExpanded ? <CloseOutlined /> : <MenuOutlined />}
        </button>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul>
            <li>
              <Link
                to={URLS.whyItWorks}
                className={url === URLS.whyItWorks ? "active" : ""}
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
              >
                Why it Works
              </Link>
            </li>
            <li>
              <Link
                to={URLS.whoWeAre}
                className={url === URLS.whoWeAre ? "active" : ""}
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
              >
                Who We Are
              </Link>
            </li>
            <li>
              <p className="pointer mb-0" onClick={showDrawer}>
                Check Balance
              </p>
              <Drawer
                title="Check Balance"
                height="auto"
                bodyStyle={styles.body}
                className="custom-drawer"
                placement="top"
                closable={true}
                onClose={onClose}
                open={showCheckBalance}
                size="default"
                closeIcon={<CloseCircleOutlined />}
              >
                <CheckBalance reset={showCheckBalance} />
              </Drawer>
            </li>
            <li>
              <p className={`pointer active-card-top-menu mb-0 ${url === URLS.registerCard || url === URLS.activateCard ? "active" : ""}`}>
                Activate Epik Gift Card
              </p>
              <ul>
                <li>
                  <Link
                    to={URLS.activateCard}
                    className={url === URLS.activateCard ? "active" : ""}
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    Activate your Card
                  </Link>
                </li>
                <li>
                  <Link
                    to={URLS.registerCard}
                    className={url === URLS.registerCard ? "active" : ""}
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    Register your Card
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to={URLS.contact}
                className={url === URLS.contact ? "active" : ""}
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
              >
                Contact
              </Link>
            </li>
            <li>
              <PopoverButtton className="shadow-bottom nav-button" text="Buy an Epik Gift Card" />
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navebar;
