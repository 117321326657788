import React from "react";
import { Col, Row } from "antd";

import WavesFrame from "../../waveFrame";
import PaperHeader from "../../paper-header";

const PrivacyPolicy = () => {
  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="contactMain app-padding">
        <Col md={{ span: 24 }} lg={{ span: 20, offset: 2 }}>
          <PaperHeader
            title={"My Epik Privacy Policy"}
            fontSize="app-secondary-title"
            titleClass="extraBold mb-74"
          />
          <PaperHeader
            title={`This Privacy Notice (commonly referred to as a “Privacy Policy”) explains how My Epik, Inc., and its affiliated companies, process the Personal Information of website visitors, users of our apps, users of our gift card and branded payments services. It also addresses the policies that apply to how we process the Personal Information of the customers of the many of brands who use My Epik Network platforms to provide services to those customers.`}
            fontSize="text-base"
            titleClass="mb-40"
          />
          <PaperHeader
            title={`You should read and understand this Notice because it constitutes the core of our obligations to you when you use this website, when you provide your Personal Information to us either directly or through automated processes. For your convenience we have summarized several of the most important points, which are:`}
            fontSize="text-base"
            titleClass="mb-40"
          />

          <ul className="text-base" style={{ color: "#1b1b5b" }}>
            <li className="mb-25">We collect and process your personal information in order to provide services to you, either as a direct customer of My Epik, or as a customer of one of the businesses for whom we provide services.</li>
            <li className="mb-25">
            We also use your personal information for our own legitimate and lawful business interests, including (but not limited to): improving our websites and services, communicating information about our services through direct advertising (such as email) and indirect advertising (such as ads appearing on other websites), conducting fraud detection and prevention activities, maintaining records and communicating notices that are required by law.
            </li>
            <li className="mb-25">
            We do not sell your information.
            </li>
            <li className="mb-25">We may share your information with the service providers needed to deliver our services to you, but those service providers are bound by law and contract to only use your information for the purposes we specify.</li>
            <li className="mb-25">We may receive your information when we function as a service provider for other companies; we are bound by law and contract to only use your information for the purposes they have specified.</li>
            <li className="mb-25">This Notice applies to websites and mobile applications operated by My Epik, and covers your personal information whether provided via those channels as well as any information you might provide to us when you call, email, or send postal mail to our Customer Service.</li>
            <li className="mb-25">For websites and apps that we operate on behalf of other companies, those companies will provide their own privacy notices. If they provide their own privacy notice, that company’s notice will govern how your information is processed. Please be sure to review the notice provided on the site you are using.</li>
            <li className="mb-40">Data protection laws in your jurisdiction may provide you with rights to access or delete your personal information and this policy provides you with instructions on how we do this. For example, if you are a resident of the European Union or the State of California in the United States, laws in those jurisdictions grant you specific rights that are discussed below in the section called “Your Rights.”</li>
          </ul>
          <PaperHeader
            title={`You are strongly encouraged to read this Privacy Notice in its entirety, not just the above highlights, so that you will fully understand My Epik’s commitment to responsible data governance and the protection of your personal information. Please also note that this Notice may have changed since the last time you read it, so you should make sure that you have familiarized yourself with any terms that may have changed since the last time you read it.`}
            fontSize="text-base"
            titleClass="mb-40"
          />

          <PaperHeader
            title={"Scope of Practices"}
            fontSize="text-2lg"
            titleClass="extraBold mb-25"
          />
          <PaperHeader
            title={`WHO`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="This Privacy Notice (“Notice”) applies to the activities of My Epik, Inc., and its affiliates (“My Epik,” “we,” “us,” or “our”). It applies to our usage of your personal data, whether you are an individual consumer (“business to consumer”) or you are interacting with us as part of your job (“business to business”)."
          />
          <PaperHeader
            title={`WHAT`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="This Notice describes how we collect and process your personal information (which includes “personal data” or “personal information” as defined under applicable data protection laws) and the rights you have regarding such data."
          />
          <PaperHeader
            title={`WHERE`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="This Notice applies to our collection and processing of personal data about users of our websites, mobile applications, and the services and features therein (together the “Sites”), as well as the data we collect during the course of providing our services (the “Services”). It also applies to those channels through which individuals communicate with us about our Sites and Services, whether in person, by telephone, by postal mail, e-mail, or other means."
          />
          <PaperHeader
            title={`WHEN`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="On some Sites (including, but not limited to: epikcard.com, we function as a Controller of your data. This means we define the purposes for which your personal information is processed, and we control the means by which your information is processed. When we act as a Data Controller, we bear the primary responsibility to you, the Data Subject, for protecting your information and honoring your rights."
          />
          <PaperHeader
            title={`When we act as a Data Processor on behalf of another Data Controller, we collect, use, and disclose certain personal information only under the Controller’s instruction, and our processing of your personal information is subject to their instructions and privacy policies. For any Site operated by us on behalf of another company, their privacy notice will appear on the site and that privacy notice will be the applicable policy.`}
            fontSize="text-base"
            titleClass="mb-40"
          />
          <PaperHeader
            title={`Some of My Epik’s Services may be offered through third parties, such as banks or other financial institutions. In those cases, you will see privacy notices provided by those companies alongside the My Epik’s Privacy Notice. The My Epik Privacy Notice will apply to your usage of that Site while the privacy notice provided by the third party will apply to their use of their customers’ personal information in the delivery of their financial services. Please read both notices carefully to understand the differences between the applicability of one policy versus the other.`}
            fontSize="text-base"
            titleClass="mb-40"
          />
          <PaperHeader
            title={`Please note: Links to third-party websites are provided solely for your convenience; when you go to those sites, your experience there is subject to the privacy notices and terms of use of those third-party sites, not ours, unless you are explicitly told otherwise.`}
            fontSize="text-base"
            titleClass="mb-40"
          />


          <PaperHeader
            title={"Personal Information We Collect"}
            fontSize="text-2lg"
            titleClass="extraBold mb-25"
          />
          <PaperHeader
            title={`We may collect personal information directly from you, through third parties, or automatically through our Sites and related to our Services, subject to applicable laws as set out below. Where the personal information we collect is needed to comply with law, or to enter into or perform an agreement with you, we will inform you at the time of such data collection. If we cannot collect this data, we may be unable to register you as a client, to complete certain transactions, or provide products or services to you.`}
            fontSize="text-base"
            titleClass="mb-40"
          />

          <PaperHeader
            title={"PERSONAL INFORMATION WE COLLECT DIRECTLY FROM YOU"}
            fontSize="text-base"
            titleClass="extraBold mb-25"
          />
          <ul className="text-base" style={{ color: "#1b1b5b" }}>
            <li className="mb-25">Contact information, such as name, email address, mailing address, fax or phone number;</li>
            <li className="mb-25">Payment and financial information, such as credit or other payment card information, bank account, or billing address;</li>
            <li className="mb-25">Shipping address and related details;</li>
            <li className="mb-25">Unique identifiers such as username, account number, or password;</li>
            <li className="mb-25">Preference information such as product wish lists, order history, or marketing preferences;</li>
            <li className="mb-25">Information about your business such as company name, size, or business type; AND </li>
            <li className="mb-40">Demographic information, such as when you provide your age, gender, interests and ZIP or postal code.</li>
          </ul>
          <PaperHeader
            title={`COMMENTS, POSTS, CHATS, AND SUBMISSIONS`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="When you submit online forms, participate in surveys, contests, promotions, or sweepstakes, join online chat discussions or post on a blog, request customer support, or submit testimonials, we collect your personal information, such as contact information, and other information you choose to share. Some of our Sites offer publicly accessible blogs. Any information you provide in these areas may be read, collected, and used by others who access them."
          />
          <PaperHeader
            title={`TESTIMONIALS`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="Some of our Sites make available the ability for you to provide reviews or testimonials. With your consent, we may use your testimonial and your name, e.g., to display personal testimonials of satisfied customers on certain Sites and in print advertisements."
          />
          <PaperHeader
            title={`LOCATION`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="On some Sites we collect geolocation-based information for fraud prevention purposes. With your consent, we may also collect your precise location-based information for purposes such as to help you locate a store offering our products and services in your area. You may withdraw your consent to the processing of location-based information at any time by changing the settings on your device. If you do, you might not be able to use certain features, especially when we use location-based information to prevent fraud."
          />
          <PaperHeader
            title={`PERSONAL INFORMATION WE COLLECT FROM THIRD PARTIES`}
            fontSize="text-base extraBold"
            titleClass="mb-0"
            headerClass="mb-25"
            subTitle="Sometimes, we may collect personal information from third-party sources. For example, subject to applicable law, we may confirm your address with the postal service, or we may receive personal information about you from our clients who use our Services to provide you with incentives. Similarly, if our users choose to send a gift to a friend through our Sites, we will ask for the friend’s name and contact details."
          />
          <PaperHeader
            title={<p>Certain websites also may offer a referral service where users may refer other people they know to our Services, subject to restrictions under applicable local laws. If you choose to use our referral service to tell your friends about our Services, we will provide you with a referral code and signup instructions that you can share with your friends. Where permitted by local law, we conduct such referrals on an opt-out basis. If personal information about you has been provided to us and you want us to delete it, you may email us at <a href="mailto:info@myepik.com" >info@myepik.com</a></p>}
            fontSize="text-base"
            headerClass="mb-40"
          />


          
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default PrivacyPolicy;
