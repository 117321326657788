import React from "react";
import useStyles from "../../hooks/use-styles";

const OutlineButton = (props: any) => {
  const {
    secondaryButtonLabelColor,
    secondaryButtonBackground,
    secondaryButtonBorder,
  } = useStyles([
    "secondaryButtonLabelColor",
    "secondaryButtonBackground",
    "secondaryButtonBorder",
  ]);
  const { text, style, className, ...buttonProps} = props;
  return (
    <button
    className={`custom-button ${className!}`}
      style={{
        backgroundColor: secondaryButtonBackground,
        border: `1px solid ${secondaryButtonBorder}`,
        color: secondaryButtonLabelColor,
        ...style
      }}
      {...buttonProps}
    >
      {text}
    </button>
  );
};

export default OutlineButton;
