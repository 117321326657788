import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../hooks/use-styles";

interface LinkTextProps {
  text: any;
  onClick?: (details?: any) => void;
  className?: string;
  fontSize?: string;
  link: any;
  color?: string;
  children?: JSX.Element;
}

const LinkText = ({
  text,
  onClick,
  className,
  fontSize,
  link,
  color,
  children,
}: LinkTextProps) => {
  const { primaryLabelColor } = useStyles(["primaryLabelColor"]);

  return (
    <p className={`${fontSize ? fontSize : "text-sm"} ${className!}`}>
      <Link
        to={link}
        onClick={onClick}
        style={{ color: color ? color : primaryLabelColor }}
      >
        {text}{" "}
        {children && (
          <span style={{ verticalAlign: "middle", marginLeft: "5px" }}>
            {children}
          </span>
        )}
      </Link>
    </p>
  );
};

export default LinkText;
