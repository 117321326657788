import React from "react";
import { Col, Row } from "antd";

import WavesFrame from "../../../components/waveFrame";
import PaperHeader from "../../../components/paper-header";
import ContactUsImage from "../../../statics/assets/Frames/not-found.png";
import CustomButton from "../../../components/custom-button";
import { useNavigate } from "react-router-dom";



const PageNotFound = () => {
  const navigate = useNavigate();
  const backToHome = () =>{
    navigate('/')
  }
  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 50]} className="contactMain app-padding" >
        <Col xs={{ span: 22 }} md={{ span: 10 }} lg={{ span: 7 }} className="d-flex flex-column justify-center">
          <h1 className="primaryColor app-secondary-title extraBold mb-25">
            😨 Oops
          </h1>
          <PaperHeader
            title={
              "Sorry, the page you are looking for doesn't exist or has been moved."
            }
            titleClass="mb-40"
            fontSize="text-md"
          />

          <CustomButton
            type="primary"
            text="Take Me Home"
            className="not-found text-md shadow-bottom buttonLarge"
            onClick={backToHome}
          ></CustomButton>
        </Col>

        <Col xs={{ span: 22 }} md={{ span: 10, offset:2}} lg={{ span: 8, offset: 5 }}>
          <img src={ContactUsImage} style={{margin: '0rem'}}  alt="contact us"/>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default PageNotFound;
