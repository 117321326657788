import React, { FC, useEffect, useState, useRef } from "react";
import { Col, Row, message, Form, Input } from "antd";
import "./check.style.css";
import PaperHeader from "../../paper-header";
import type { FormInstance } from "antd/es/form";
import CustomButton from "../../custom-button";
import {
  formatCardNumber,
  showErrorNotification,
} from "../../../utils/common-utils";
import { checkBalance } from "../../../apis/auth_api";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../../credentials";

interface CheckBalanceProps {
  reset?: boolean;
  onSelect?: (pageLimit: string) => void;
}

const CheckBalance: FC<CheckBalanceProps> = ({ reset, onSelect }) => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const formRef = React.useRef<FormInstance>(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [totalBalance, setTotalBalance] = useState("0");
  const validateMessages = {
    required: "${name} is required!",
    number: {
      min: "${name} must ${min} digits long",
    },
  };

  useEffect(() => {
    onReset();
    resetRecaptcha();
  }, [reset]);

  const handleChange = (event: any, type: any) => {
    let { value } = event.target;

    if (type === "card number") {
      value = value.replace(/ /gi, "");
      if (isNaN(value)) {
        formRef.current?.setFieldsValue({
          [type]: formatCardNumber(value.slice(0, -1)),
        });
        return;
      } else {
        formRef.current?.setFieldsValue({
          [type]: formatCardNumber(value),
        });
      }
    }
  };

  const onFinish = (values: any) => {
    if (recaptchaValue) {
      checkBalance({cardNumber: values["card number"].replace(/ /g, ""), captchaToken: recaptchaValue})
        .then((res) => {
          if (res?.status === 200) {
            message.success("Request submitted successfully.");
            setTotalBalance(res?.data);
            resetRecaptcha();
          } else {
            message.error(res?.message);
          }
        })
        .catch((error) => {
          // Handle error
          showErrorNotification(error);
        });
    } else {
      message.error("reCAPTCHA validation required");
    }
  };

  const onReset = () => {
    formRef.current?.resetFields();
    setTotalBalance("0");
  };

  const handleCaptcha = (response: any) => {
    setRecaptchaValue(response);
  };

  const resetRecaptcha = () =>{
    if (captchaRef.current) {
      captchaRef.current.reset();
      setRecaptchaValue(null);
    }
  }

  return (
    <Row gutter={[0, 0]} justify="center" align="middle">
      <Col
        xs={{ span: 22 }}
        md={{ span: 12, offset: 1 }}
        lg={{ span: 10, offset: 2 }}
      >
        <PaperHeader
          title={"Need to check the balance on your Epik Gift Card?"}
          fontSize="text-25"
          titleClass="bold mb-12"
        />

        <Form
          layout="vertical"
          ref={formRef}
          name="check balance form"
          onFinish={onFinish}
          size="large"
          validateMessages={validateMessages}
          className="contact-form"
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            name="card number"
            label="Enter your card number"
            rules={[
              { required: true },
              {
                min: 19,
                message: "Card number is not valid",
              },
            ]}
          >
            <Input
              placeholder="0000 0000 0000 0000"
              maxLength={19}
              onChange={(event) => handleChange(event, "card number")}
              suffix={
                <CustomButton
                  type="primary"
                  htmltype="submit"
                  text="Apply"
                  className="shadow-bottom checkBtn"
                  backgroundColor="white"
                ></CustomButton>
              }
            />
          </Form.Item>
          <Form.Item>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={SITE_KEY()}
              onChange={handleCaptcha}
            />
          </Form.Item>
        </Form>
      </Col>

      <Col
        xs={{ span: 22 }}
        md={{ span: 8, offset: 2 }}
        lg={{ span: 6, offset: 2 }}
      >
        <h1 className="balance mb-0"> ${totalBalance}</h1>
      </Col>
    </Row>
  );
};

export default CheckBalance;
