import React, {FC, useEffect, useState} from "react";
import { Col, Row } from "antd";

import PaperHeader from "../../../paper-header";
import WavesFrame from "../../../waveFrame";
import frameBG from "../../../../statics/assets/Frames/orderFrame.png";
import pinkLogo from "../../../../statics/assets/primaryLogo.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {ThankYouInterface} from "../../../../interfaces/cardData";

const ThankYou: FC<ThankYouInterface> = ({
  confirmOrderData,
  onOrderNew,
  data,
}) => {
  const navigate = useNavigate();
  const [createdOrderCode, setCreatedOrderCode] = useState<string>(confirmOrderData?.order_code);
  const [cardType, setCardType] = useState<string>(data[0]?.cardType)
  
  useEffect(() => {
    const storageCreatedOrderCode = localStorage.getItem("createdOrderCode")!;
    const storageOrderCardType =  localStorage.getItem("orderCardType")!;
    if(!createdOrderCode) {
      setCreatedOrderCode(storageCreatedOrderCode);
    }
    if(!cardType) {
      setCardType(storageOrderCardType);
    }
    localStorage.removeItem("currentOrderStep");
    localStorage.removeItem("confirmationStep");
    localStorage.removeItem("createdOrderCode");
    localStorage.removeItem("cardsList");
    localStorage.removeItem("senderDetail");
  }, [cardType, createdOrderCode]);

  const onDone = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <WavesFrame className="w-100 h-100" BgColor="#CE0F69">
      <Row className="epik-card-main">
        <Col span="24">
          <WavesFrame className="epik-inner" BgImage={frameBG}>
            <WavesFrame className="white-board-thanks" BgColor="#ffffff">
              <Row gutter={[0, 0]} justify={"center"}>
                <Col xs={{ span: 24 }} md={{ span: 16 }}>
                  <div className="inner-content thanks-main text-center">
                    {/* <img src={epikWhite} alt='epik logo' /> */}
                    <h1
                      className="text-62px extraBold mb-12"
                      style={{ color: "#1B1B5B" }}
                    >
                      Thanks for placing your order. Have an{" "}
                      <img src={pinkLogo} alt="Epik Card" /> day.
                    </h1>
                    {/* <PaperHeader
                      title={
                        "Thank you for placing your order, and have an  Epik  day. "
                      }
                      fontSize="text-62px"
                      titleClass="extraBold mb-12"
                      titleColor="#1B1B5B"
                    /> */}
                    <PaperHeader
                      title={`Order Number: ${createdOrderCode}`}
                      fontSize="text-25"
                      titleClass="extraBold mb-12 mt-1"
                      titleColor="#1B1B5B"
                    />
                    <PaperHeader
                      title={`${moment().format("ll")}`}
                      titleClass="extraBold mb-12"
                      titleColor="#1B1B5B"
                    />
                    {cardType !== "digital card" && (
                      <PaperHeader
                        title={`Your order is being processed, and your card(s) will ship within two business days. Cards ordered for future delivery dates will be delivered within 2 - 3 days of the selected date.`}
                        titleClass="text-base weight-400 mt-1"
                        titleColor="#1B1B5B"
                      />
                    )}
                    <button
                      className="pointer extraBold mt-2"
                      onClick={() => onOrderNew(false, false)}
                      type="button"
                    >
                      Order Another Card
                    </button>
                    <p className="pointer done mt-1 mb-0" onClick={onDone}>
                      All Done
                    </p>
                  </div>
                </Col>
              </Row>
            </WavesFrame>
          </WavesFrame>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default ThankYou;
