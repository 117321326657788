import React from "react";
import { Layout } from "antd";

import LandinBanner from "./LandinBanner";
import WhoWeAreSection from "./WhoWeAre";
import SectionThird from "./Section3";

const { Content } = Layout;

const EpikLandingPageOne = () => {
  return (
    <Content>
      <LandinBanner />
      <WhoWeAreSection />
      <SectionThird />
    </Content>
  );
};

export default EpikLandingPageOne;
