import React, { FC, useEffect } from "react";
import PaperHeader from "../../../../paper-header";
import CustomButton from "../../../../custom-button";
import { orderTotal } from "../orderSummary.utils";

interface SummaryLayoutInterface {
  data?: any;
  OnNewOrder: Function;
}

const SummaryLayout: FC<SummaryLayoutInterface> = ({ data, OnNewOrder }) => {
  useEffect(() => {});

  const calculateBulkQty = () => {
    return data.reduce(
      (n: any, { totalQty }: any) => +n + +totalQty,
      0
    )
  }

  return (
    <div>
      <PaperHeader
        title="Order Summary"
        fontSize="text-lg"
        titleClass="weight-500 mb-15"
        titleColor="white"
      />
      <div className="summaryInfoCard">
        <span className="d-flex justify-between flex-wrap">
          <PaperHeader
            title="Total Cards"
            fontSize="text-md"
            titleColor="black"
          />
          <PaperHeader
            title={
              data[0]?.cardType === "bulk card"
                ? calculateBulkQty()
                : data?.length
            }
            fontSize="text-md"
            titleColor="black"
            titleClass="weight-500"
          />
        </span>
        <span className="d-flex justify-between flex-wrap">
          <PaperHeader
            title="Order Total"
            fontSize="text-lg"
            titleColor="black"
            titleClass="weight-500 mb-0"
          />
          <PaperHeader
            title={`${orderTotal(data)}`}
            fontSize="text-lg"
            titleColor="black"
            titleClass="extraBold mb-0"
          />
        </span>
      </div>

      <div className="d-flex justify-center mt-2">
        <CustomButton
          type="primary"
          htmltype="button"
          text="+ Add Another Card"
          className="shadow-bottom addCard-button"
          backgroundColor="#A5DE00"
          onClick={OnNewOrder}
        ></CustomButton>
      </div>
    </div>
  );
};

export default SummaryLayout;
