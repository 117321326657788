import React, { FC, useEffect } from "react";
import { Form, Input, Row, Col, message } from "antd";
import FormButtons from "../form-buttons";
import { OrderSummaryInterface } from "../../../../interfaces/cardData";
import { formatPhoneNumber } from "../../../../utils/common-utils";

const ContactInfo: FC<OrderSummaryInterface> = ({
  next,
  prev,
  onCancel,
  confirmOrderData,
  current,
  trigger,
}) => {
  const [form] = Form.useForm();
  const setFormData = () => {
    confirmOrderData && form.setFieldsValue(confirmOrderData);
  };

  useEffect(() => {
    setFormData();
    document?.getElementById("yourEmail")?.focus();
    // input.addEventListener("keypress", function(event) {
    //   if (event.key === "Enter") {
    //     event.preventDefault();
    //     next();
    //   }
    // });
  }, []);

  useEffect(() => {
    if (trigger !== 0 && current === 0) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = (values: any) => next(values);

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo?.errorFields[0]?.errors[0]);
  };

  const handleChange = (event: any, type: any) => {
    let { value } = event.target;
    value = value.replace(/ /gi, "");
    form?.setFieldsValue({
      [type]: formatPhoneNumber(value),
    });
  };

  return (
    <div className="step-form-fields">
      <Form
        name="contactInfo"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        form={form}
        requiredMark={false}
        className="mb-74"
      >
        <Row gutter={[0, 10]} className="form-row" id="epik-scroll">
          {/* <Col  xs={{ span: 24 }} lg={{ span: 14, offset: 5}}> */}
          <Col
            xs={{ span: 24 }}
            md={{ span: 11 }}
            xxl={{ span: 14, offset: 5 }}
          >
            <Form.Item
              name="contactEmail"
              label="Your Email"
              rules={[
                {
                  required: true,
                  message: "Please input email!",
                  type: "email",
                },
              ]}
            >
              <Input id="yourEmail" autoFocus placeholder="Email Here" />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 11, offset: 2 }}
            xxl={{ span: 14, offset: 5 }}
          >
            <Form.Item
              name="emailConfirm"
              label="Confirm Email"
              dependencies={["contactEmail"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your email!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("contactEmail") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Email not match!"));
                  },
                }),
              ]}
            >
              <Input autoFocus placeholder="Confirm Email Here" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} xxl={{ span: 14, offset: 5 }}>
            <Form.Item
              name="contactPhoneNumber"
              label="Phone Number"
              rules={[
                { required: true, message: "Phone number is required!" },
                { min: 14, message: 'Enter a 10-digit number.' }
              ]}
            >
              <Input
                placeholder="(xxx) 777 7777"
                maxLength={14}
                onChange={(event) => handleChange(event, "contactPhoneNumber")}
              />
            </Form.Item>
          </Col>
        </Row>

        <FormButtons
          // handleNext={next}
          onCancel={onCancel}
          customClass={"confirmButtons"}
          nextButtonText={"Continue"}
          cancelButtonText={"Cancel"}
          handlePrevious={prev}
        />
      </Form>
    </div>
  );
};

export default ContactInfo;
