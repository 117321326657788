import React, { FC, useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import { orderBasinInterface } from "../../../../interfaces/cardData";


const AmountForm: FC<orderBasinInterface> = ({ next, current, trigger, data, listStep }) => {
  const [qty, setQty] = useState(5);
  const [form] = Form.useForm();
  const setFormData = () => {
    data && form.setFieldsValue(data);
    setQty(form.getFieldValue('totalQty'));
  };

  useEffect(() => {
    setFormData();
    var input = document?.getElementById("amount")?.focus();
    if (current === listStep) {
      return input;
    }
  }, [current]);

  // const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
  //   console.log(event.key);
  //   if (event.key === "Enter") {
  //     next();
  //   }
  // };

  useEffect(() => {
    if (trigger !== 0 && current === listStep) {
      form.submit();
    }
  }, [trigger]);

  const handleChange = (event: any, type: string) => {
    let { value } = event.target;
    console.log(value)
    value = value.replace(/ /gi, "");
    if (isNaN(value)) {
      form?.setFieldsValue({
        [type]: value.slice(0, -1),
      });
      return;
    } else {
      form?.setFieldsValue({
        [type]: value,
      });
    }
  };
  const decreaseQty = () => {
    if (qty !== 5) setQty(qty - 1);
  };

  const increaseQty = () => {
    if (qty !== 50) setQty(qty + 1);
  };

  const onChange = (e: any) => {
    const v = e.target.value;
    setQty(v);
  };

  const onFinish = (values: any) => {
    values.totalQty = qty;
    if(values.amount < 10 || values.amount > 500)
    {
      form.setFields([
        {
          name: "amount", // required
          errors: ["Amount must be between $10-$500."],
        }
      ]);
    }
    else{
      next(values);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo?.errorFields[0]?.errors[0]);
  };

  return (
    <Form
      name="amountForm"
      onFinish={onFinish}
      autoComplete="off"
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={{ totalQty: 5}}
      onFinishFailed={onFinishFailed}
    >
      <div className="step-form-fields">
        <Row gutter={[0, 0]}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              name="amount"
              label="How much is it for?"
              rules={[
                { required: true, message: "amount is required!" }
              ]}
              extra="Enter amount between $10 to $500"
            >
              <Input
                id="amount"
                maxLength={3}
                prefix="$"
                onChange={(event) => handleChange(event, "amount")}
                placeholder="100"
              />
            </Form.Item>
          </Col>
          {data && data?.cardType?.toLowerCase() === 'bulk card' && 
            <Col span={24}>
            <Form.Item
              name="totalQty"
              label="Choose Quantity"
              rules={[{ required: true, message: "Please input quantity!" }]}
              className="quantity-item"
              extra="Enter quanity between 5 to 50"
            >
              <div className="qty-field">
                <Button type="link" onClick={decreaseQty}>
                  {" "}
                  -{" "}
                </Button>
                {/* <input autoFocus type="text" value={qty} onChange={(e) => onChange(e)} /> */}
                <Input
                  id="quantity"
                  // onPressEnter={handleKeyPress}
                  value={qty}
                  onChange={(e) => onChange(e)}
                  readOnly
                />
                <Button type="link" onClick={increaseQty}>
                  {" "}
                  +{" "}
                </Button>
              </div>
            </Form.Item>
          </Col>

          }
          
        </Row>
      </div>
    </Form>
  );
};

export default AmountForm;
