import React, { useState } from "react";
import { Row } from "antd";
import VerifyCardForm from "./verify-card-form";
import "./style.css";
import WavesFrame from "../../waveFrame";
import RegisterCardForm from "./register-card-form";
import RegisterCardConfirm from "./register-card-confirm";
import {CardData, UserData} from "../../../interfaces/cardData";

const RegisterCard = () => {

  const [current, setCurrent] = useState(0);
  const [cardData, setCardData] = useState(JSON.parse(localStorage.getItem("cardData")!) || {});
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")!) || {});

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const handleSetCardData = async (cardData: CardData) => {
    setCardData(cardData);
  };

  const handleSetUserData = async (userData: UserData) => {
    setUserData(userData);
  };

  const previous = () => {
    setCurrent((prev) => prev - 1);
  };

  const formProps = {
    cardData,
    userData,
    current,
    handleSetCardData,
    handleSetUserData,
    next,
    prev: previous,
  };

  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="register-card-main app-padding">
        {current === 0 && <VerifyCardForm {...formProps}/>}
        {current === 1 && <RegisterCardForm {...formProps}/>}
        {current === 2 && <RegisterCardConfirm {...formProps}/>}
      </Row>
    </WavesFrame>
  );
};

export default RegisterCard;
