import React from "react";
import { Row, Col } from "antd";
import frame from "../../../../statics/assets/Frames/who-we-are/Frame1.svg";
import PaperHeader from "../../../paper-header";
import WavesFrame from "../../../waveFrame";

const LandinBanner = () => {
  return (
    <WavesFrame BgColor="#B1DB42">
      <div className="section3">
        <Row gutter={[0, 24]} className="mt-5 app-padding">
          <Col xs={{ span: 22 }} md={{ span: 10 }} >
            <div className="landing-header rightSide">
              <PaperHeader
                title={"Who We Are"}
                fontSize="text-62px"
                titleClass="title bold mb-28"
                titleColor="#1D4E05"
              />

              <PaperHeader
                title={`We're a diverse group of professionals and concerned parents dedicated to creating a
                positive, family-friendly gift/gifting experience with the Epik Gift Card. Accessible at
                different price points and redeemable virtually anywhere, our aim is to encourage kids
                and teens to embrace their inner Epik-ness`}
                fontSize="text-md"
                titleClass="subtitle mb-0"
                titleColor="#1D4E05"
              />
            </div>
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 12, offset: 2 }}>
            <img src={frame} alt="Epik Gift Card" className="w-100" />
          </Col>
        </Row>
      </div>
    </WavesFrame>
  );
};

export default LandinBanner;
