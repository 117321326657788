import React from "react";
import { Col, Row } from "antd";

import WavesFrame from "../../waveFrame";
import PaperHeader from "../../paper-header";
import FeeImage from "../../../statics/assets/fee-schedule.svg";

const Terms = () => {
  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row gutter={[0, 24]} className="contactMain app-padding">
        <Col md={{ span: 24 }} lg={{ span: 20, offset: 2 }}>
          <PaperHeader
            title={"Terms with Cardholder Agreement"}
            fontSize="app-secondary-title"
            titleClass="extraBold mb-74"
          />
          <PaperHeader
            title={"Epik Gift Card CARDHOLDER AGREEMENT"}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={"CARDHOLDER AGREEMENT / TERMS & CONDITIONS"}
            fontSize="text-base"
            titleClass="mb-25"
          />

          <PaperHeader
            title={`IMPORTANT – PLEASE READ CAREFULLY.  THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION (“ARBITRATION” SECTION) REQUIRING ALL CLAIMS TO BE RESOLVED BY WAY OF BINDING ARBITRATION AND WAIVING A RIGHT TO TRIAL BY JURY AND TO PARTICIPATE IN CLASS ACTIONS. IF YOU DO NOT AGREE TO THE TERMS OF THE ARBITRATION PROVISION, DO NOT ACTIVATE OR USE THE CARD, SAVE YOUR RECEIPT AND CALL 1-844-404-0264 TO CANCEL THE CARD AND TO REQUEST A REFUND.`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`IMPORTANT – GIVE THIS AGREEMENT TO THE GIFT CARD RECIPIENT`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`TREAT CARD LIKE CASH. NEVER PROVIDE CARD OR CARD/ACCOUNT INFORMATION TO SOMEONE YOU DON’T KNOW – YOU MAY LOSE YOUR MONEY WITH NO RECOURSE.`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`CUSTOMER SERVICE CONTACT INFORMATION:`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
          />

          <PaperHeader
            title={`Address: 3641 Mt Diablo Blvd, #166 Lafayette CA 94549`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`Website: www.epikcard.com`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`Toll-Free Customer Service Number: +1 844 404 0264`}
            fontSize="text-base"
            titleClass="mb-40"
          />

          <PaperHeader
            title={`This document constitutes the agreement (“Agreement”) outlining the terms and conditions under which a Epik Card Gift (“Card”) has been issued to you. The Epik Card Gift Card is a prepaid Card issued by Sutton Bank. All Cards are issued by Sutton Bank and distributed and serviced by My Epik, Inc., which may be contacted by phone at +1 844 404 0264 or by mail at 3641 Mt Diablo Blvd, #166 Lafayette CA 94549. By accepting and using this Card, signing the back of the Card, activating the Card or authorizing any person to use the Card, you agree to be bound by the terms and conditions contained in this Agreement. You agree to sign the back of the Card immediately upon receipt. The Card will remain the property of Sutton Bank and must be surrendered upon demand. The Card is nontransferable, and it may be canceled, repossessed, or revoked at any time without prior notice subject to applicable law. These terms and conditions apply to both the purchaser and any other user of the Card. It is the purchaser’s obligation to provide these terms and conditions to any user; however, new terms and conditions may be provided, or any other questions or concerns answered, by calling +1 844 404 0264.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`For purposes of this Cardholder Agreement, Sutton Bank’s business days are Monday through Friday, excluding federal holidays.`}
            fontSize="text-base"
            titleClass="mb-40"
          />

          <PaperHeader
            title={`1.	ABOUT YOUR CARD`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="The Card is a prepaid Card loaded with a specific amount of funds, redeemable to buy goods and services in the U.S. at most places Discover and Maestro are accepted. No additional funds may be added to this Card. In this Agreement “You” and “your” mean the person or persons who have received the Card and are authorized to use the Card as provided for in this Agreement. “We”, “us”, “our”, and “Bank” mean Sutton Bank, our successors, affiliates or assignees. Unless it would be inconsistent to do so, word and phrases used in this Agreement should be construed so that the singular includes the plural and the plural includes the singular."
          />
          <PaperHeader
            title={`You acknowledge and agree that the value available on your Card or in your Virtual Account is limited to the funds that have been loaded to your Card. The Card is a prepaid card and can only be used to access value that you have previously loaded onto it. The Card is NOT a credit card or charge card and your use of the Card or in the Virtual Account will not enhance your credit rating.  The funds on the Card or in the Virtual Account are NOT FDIC-insured. The Card is not a checking account or connected in any way to any account other than a stored value account where your funds are held. The Card will remain the property of Sutton Bank and must be surrendered upon demand. The Card is not designed for business use, and we may cancel your Card if we determine that it is being used for business purposes. We may refuse to process any transaction that we believe violates the terms and conditions of this Agreement or that may be fraudulent or illegal.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`Keep record of your Account in case your Card is lost, stolen, or destroyed. Please read this Agreement carefully and keep it for future reference.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`BY ACTIVATING YOUR CARD OR PARTICIPATING IN THE PROGRAM IN ANY WAY, YOU REPRESENT THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT AND YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT AND [PRIVACY POLICY] TO VIEW, PRINT AND SAVE OUR PRIVACY POLICY.`}
            fontSize="text-base weight-500"
            titleClass="mb-25"
          />

          <PaperHeader
            title={`2.	USING YOUR CARD`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="You may use your Card to obtain goods or services wherever the Card is honored. The Card is not a device that accesses money in an individual checking or savings account. When you use your Card, you are redeeming the value on the Card and not making a withdrawal from a checking or savings account. You make use your Card to make purchases at any merchant in the United States that accepts the Card Network’s cards, subject to your available Card Account balance and other terms and conditions of this Agreement. You may not use your Card for any online gambling, escort services or any illegal transaction."
          />
          <PaperHeader
            title={`Each time you use your Card, you authorize us to reduce the value available on your Card by the amount of the transaction. Your Card cannot be redeemed for cash or be used to obtain cash in any transaction. Your Card is valid in the U.S. only. It cannot be used at merchants outside of the United States, including internet and mail / telephone order merchants outside of the United States. Although your Card will not be accepted at locations outside of the United States, it is welcome at millions of locations within the U.S. where Discover is accepted. Virtual Accounts may be used for electronic commerce, mail order and/or telephone order purchases only and will not be accepted for purchases at physical retail locations.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`The Card is our property and is nontransferable. We may revoke or suspend your Card or any features or services of your Card at any time without cause or notice. You must surrender a revoked Card. You are required to notify us promptly if the Card is lost or stolen. `}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`For security reasons, we may limit the amount or number of transactions you can make on your Card.`}
            fontSize="text-base "
            titleClass="mb-25"
          />
          <PaperHeader
            title={`We may refuse to process any transaction that we believe may violate the terms of this Agreement.`}
            fontSize="text-base "
            titleClass="mb-25"
          />

          <PaperHeader
            title={`3.	SETTING A PERSONAL IDENTIFICATION NUMBER (“PIN”)`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="The PIN can be set or reset by calling +1 844 404 0264. You will be required to provide information about the Card (account number and security code) to set the PIN."
          />
          <PaperHeader
            title={`You should not write or keep your PIN with your Card. Never share your PIN with anyone and do not enter your PIN into any terminal that appears to be modified or suspicious. If you believe that there has been unauthorized access to your PIN, you should advise us immediately, following the procedures in the section labeled “Lost or Stolen Cards; Your Liability for Unauthorized Transactions.” CARD ARE NOT ACCEPTED AT ATMS AND CANNOT BE USED TO OBTAIN CASH IN ANY PURCHASE TRANSACTION.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`YOU ARE NOT ALLOWED TO EXCEED THE BALANCE OF THE FUNDS AVAILABLE ON YOUR CARD/VIRTUAL ACCOUNT. If you attempt to use the Card when there are insufficient funds associated with it, the transaction will generally be declined. Nevertheless, if a transaction that exceeds the balance of the funds available on your Card occurs due to a systems malfunction or otherwise, you shall remain fully liable to us for the amount of the transaction.`}
            fontSize="text-base"
            titleClass="mb-25"
          />

          <PaperHeader
            title={`4.	CHECKING YOUR BALANCE`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="You should keep track of the amount of value loaded on Cards issued to you. You may obtain information about the amount of money you have remaining in your Card account and recent transaction activity by calling +1 844 404 0264 or visiting www.myepik.com. It may also be possible to request a written copy of account transactions by calling +1 844 404 0264 or by writing us at 3641 Mt Diablo Blvd, #166 Lafayette CA 94549."
          />
          <PaperHeader
            title={`5.	AUTHORIZED USERS`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="Until you sign or use the card, you may present the Card to another person. You are responsible for all transactions initiated and fees incurred by use of the Card.  If you permit another person to have access to the Card or Card number, we will treat this as if you have authorized such use and you will be liable for all transactions and fees incurred by those persons.  You are wholly responsible for the use of each Card according to the terms and conditions of this Agreement."
          />
          <PaperHeader
            title={`6.	AUTHORIZATION HOLDS`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="You do not have the right to stop payment on any purchase transaction originated by use of your Card. With certain types of purchases (such as those made at restaurants, hotels, or similar purchases), your Card may be “preauthorized” for an amount greater than the transaction amount to cover gratuity or incidental expenses. Any preauthorization amount will place a “hold” on your available funds until the merchant sends us the final payment amount of your purchase. Once the final payment amount is received, the preauthorization amount on hold will be removed. During this time, you will not have access to preauthorized amounts. If you authorize a transaction and then fail to make a purchase of that item as planned, the approval may result in a hold for that amount of funds."
          />
          <PaperHeader
            title={`7.	RETURNS AND REFUNDS`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="If you are entitled to a refund for any reason for goods or services obtained with your Card, the return and refund will be handled by the merchant. If the merchant credits your Card, the credit may not be immediately available. While merchant refunds post as soon as they are received, please note that we have no control over when a merchant sends a credit transaction and the refund may not be available for a number of days after the date the refund transaction occurs. "
          />
          <PaperHeader
            title={`8.	RECEIPTS`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="You should get a receipt at the time you make a transaction using your Card. You agree to retain your receipt to verify your transactions."
          />

          <PaperHeader
            title={`9.	SPLIT TRANSACTIONS AND OTHER USES`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="If you do not have enough funds available in your Card account, you can instruct the merchant to charge a part of the purchase to the Card and pay the remaining amount with another form of payment. These are called “split transactions.” Some merchants do not allow cardholders to conduct split transactions. Some merchants will only allow you to do a split transaction if you pay the remaining amount in cash."
          />
          <PaperHeader
            title={`If you use your Card number without presenting your Card (such as for an internet transaction, a mail order or a telephone purchase), the legal effect will be the same as if you used the Card itself. Payment for pay-at-the-pump stations must be made inside.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`YOU ARE NOT ALLOWED TO EXCEED THE BALANCE OF THE FUNDS AVAILABLE ON YOUR CARD. If you attempt to use the Card when there are insufficient funds associated with it, the transaction will generally be declined. Nevertheless, if a transaction that exceeds the balance of the funds available on your Card occurs due to a systems malfunction or otherwise, you shall remain fully liable to us for the amount of the transaction.`}
            fontSize="text-base"
            titleClass="mb-25"
          />

          <PaperHeader
            title={`10.	OBTAINING A REPLACEMENT CARD`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="The funds on your Card do not expire. The “valid thru” date indicated on the front of your Card is not an expiration date, but is intended primarily to allow your Card to be used with certain Internet or mail/telephone order merchants, where such information may be required. After the “valid thru” date, your available funds will be temporarily unavailable until you contact +1 844 404 0264 for a replacement Card with a new “valid thru” date. You [ will or will not] be charged a replacement Card fee $5.95 if you are ordering a replacement Card due to your Card expiring in order to continue accessing unused funds. If your Card still has unused funds on it after your Card expires, you may order a new Card by calling +1 844 404 0264."
          />
          <PaperHeader
            title={`11.	PRIVACY POLICY`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="You agree and understand to the collection, use, and disclosure of your information as set forth in this Agreement and our Privacy Policy available at https://www.suttonbank.com/_/kcms-doc/85/49033/WK-Privacy-Disclosure-1218.pdf"
          />
          <PaperHeader
            title={`12.	FEE SCHEDULE`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="You agree and understand to the collection, use, and disclosure of your information as set forth in this Agreement and our Privacy Policy available at https://www.suttonbank.com/_/kcms-doc/85/49033/WK-Privacy-Disclosure-1218.pdf"
          />
          <img src={FeeImage} alt="fee schedule" />

          <PaperHeader
            title={`13.	LOST OR STOLEN CARDS; YOUR LIABILITY FOR UNAUTHORIZED TRANSACTIONS `}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="Contact us at once by calling +1 844 404 0264 if you believe the Card has been lost or stolen. You must provide your name, address, Card number, CID, and other details as requested by us to replace your Card. We cannot assist you if you do not have the Card number or do not provide us with the requested information. If we issue a replacement Card, the replacement Card will have a value equal to the Available Balance on the Card at the time you notified us of the loss or theft. Any Available Balances will be temporarily unavailable until you activate your replacement Card. NO REFUNDS WILL BE PROVIDED FOR AMOUNTS DEBITED FROM THE LOST OR STOLEN CARD BEFORE YOU NOTIFY US. You acknowledge that purchases made with prepaid card or virtual accounts, such as the Card, are similar to those made with cash. You cannot “stop payment” or “lodge a billing dispute” on such transactions. Any problems or disputes you may have regarding a purchase should be addressed directly with the merchant."
          />

          <PaperHeader
            title={`14. OUR LIABILITY FOR FAILURE TO COMPLETE TRANSACTIONS`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="If we do not complete a transaction to or from your Card on time or in the correct amount according to our Agreement with you, we will be liable for your losses and damages proximately caused by us. However, there are some exceptions. We will not be liable, for instance:"
          />
          <ol className="text-base" style={{ color: "#1b1b5b" }}>
            <li className="mb-25">If, through no fault of ours, you do not have enough funds available in your Card account to complete the transaction;</li>
            <li className="mb-25">If a merchant refuses to accept your Card;</li>
            <li className="mb-25">If an electronic terminal where you are making a transaction does not operate properly, and you knew about the problem when you initiated the transaction;</li>
            <li className="mb-25">If access to your Card has been blocked after you reported your Card lost or stolen;</li>
            <li className="mb-25">If circumstances beyond our control (such as fire, flood or computer or communication failure) prevent the completion of the transaction, despite reasonable precautions that we have taken; or</li>
            <li className="mb-25">Any other exception stated in our Agreement with you.</li>
          </ol>

          <PaperHeader
            title={`15. NO WARRANTY`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="We are not responsible for the quality, safety, legality, or any other aspect of any goods or services you purchase with your Card."
          />
          <PaperHeader
            title={`16.	AMENDMENT AND CANCELLATION`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="We may amend or change the terms of this Agreement at any time, subject to applicable law. You will be notified of any change in the manner required by applicable law prior. However, if the change is made for security purposes, we can implement such change without prior notice."
          />
          <PaperHeader
            title={`We may cancel or suspend your Card or this Agreement at any time. You may cancel this Agreement by returning the Card to us. Your termination of this Agreement will not affect any of our rights or your obligations arising under this Agreement prior to termination.`}
            fontSize="text-base"
            titleClass="mb-25"
          />

          <PaperHeader
            title={`17.	OTHER TERMS`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="Your Card and your obligations under this Agreement may not be assigned. We may transfer our rights under this Agreement. Use of your Card is subject to all applicable rules and customs of any clearinghouse or other association involved in transactions. We do not waive our rights by delaying or failing to exercise them at any time. If any provision of this Agreement shall be determined to be invalid or unenforceable under any rule, law, or regulation of any governmental agency, local, state, or federal, the validity or enforceability of any other provision of this Agreement shall not be affected. This Agreement will be governed by the law of the State of Ohio except to the extent governed by federal law."
          />
          <PaperHeader
            title={`18.	ARBITRATION`}
            fontSize="text-base extraBold"
            titleClass="mb-25"
            headerClass="mb-25"
            subTitle="You or we may elect to resolve any claim by an individual arbitrator. Claims are decided by a neutral arbitrator. If arbitration is chosen by any party, you and we hereby waive the right to litigate the claim in court or have a jury trial on that claim. Further, you and we will not have the right to participate in a representative capacity or as a member of any class pertaining to any claim subject to arbitration. "
          />
          <PaperHeader
            title={`Any claim, dispute, or controversy (“Claim”) arising out of or relating in any way to: (i) this Agreement; (ii) the Card; (iii) your acquisition of the Card; (iv) your use of the Card; (v) the amount of available funds in the Card account; (vi) advertisements, promotions or oral or written statements related to the Card, as well as goods or services purchased with the Card; (vii) the benefits and services related to the Card; or (viii) transactions on the Card, no matter how described, pleaded or styled, shall be FINALLY and EXCLUSIVELY resolved by binding individual arbitration conducted by the American Arbitration Association (“AAA”) under its Consumer Arbitration Rules in your state of residence at a location that is reasonably convenient for both you and us. For a copy of the AAA procedures, to file a Claim or for other information about the AAA, contact it at: www.adr.org.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`We will pay all fees associated with administration of arbitration, including fees to commence the arbitration. At your written request, we will consider in good faith making a temporary advance of your share of any arbitration fees, or paying for the reasonable fees of an expert appointed by the arbitrator for good cause.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`Arbitration procedures are generally simpler than the rules that apply in court, and discovery is more limited. The arbitrator’s decisions are as enforceable as any court order and are subject to very limited review by a court. Except as set forth below, the arbitrator’s decision will be final and binding. Other rights you or we would have in court may also not be available in arbitration.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`NO CLASS ACTION, OR OTHER REPRESENTATIVE ACTION, OR PRIVATE ATTORNEY GENERAL ACTION, OR JOINDER OR CONSOLIDATION OF ANY CLAIM WITH A CLAIM OF ANOTHER PERSON SHALL BE ALLOWABLE IN ARBITRATION.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`This arbitration provision shall survive: (i) the termination of this Agreement; (ii) the bankruptcy of any party; (iii) any transfer, sale or assignment of your Card, or any amounts owed on your Card, to any other person or entity; and (iv) past the “valid thru” date on your Card.  If any portion of this arbitration provision is deemed invalid or unenforceable, the remaining portions shall nevertheless remain in force.  Any different agreement regarding arbitration must be agreed to in writing.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`This arbitration agreement is made pursuant to a transaction involving interstate commerce, and shall be governed by the Federal Arbitration Act, 9 U.S.C. §§ 1-16.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`Solely for purposes of this Section (“Arbitration”), “we” or “us” shall mean the Issuer, and its respective successors, affiliates or assignees as well as any third party using or providing any product, service or benefit in connection with the Card.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`All determinations as to the scope, interpretation, enforceability and validity of this Agreement shall be made finally and exclusively by the arbitrator, which award shall be binding and final. Judgment on the arbitration award may be entered in any court having jurisdiction. `}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`You may reject this Arbitration provision by sending a written rejection notice to us at: My Epik, Inc. 3641 Mt Diablo Blvd, #166 Lafayette CA 94549. Your rejection notice must be mailed within 45 days after your first card purchase. Your rejection notice must state that you reject the Arbitration provision and include your name, address, Card number and personal signature. No one else may sign the rejection notice. If your rejection notice complies with these requirements, this Arbitration provision and any other arbitration provisions in the cardmember agreements for any other currently open Epik Card accounts you have will not apply to you, except for any claims subject to pending litigation or arbitration at the time you send your rejection notice. Rejection of this Arbitration provision will not affect your other rights or responsibilities under this section or the Agreement.  `}
            fontSize="text-base"
            titleClass="mb-40"
          />

          <PaperHeader
            title={`This Agreement is effective May 24th, 2023.`}
            fontSize="text-base"
            titleClass="mb-25"
          />
          <PaperHeader
            title={`This Card is issued by Sutton Bank, Member FDIC, pursuant to license from Discover (R) Global Network.`}
            fontSize="text-base"
            titleClass="mb-25"
          />

        </Col>
      </Row>
    </WavesFrame>
  );
};

export default Terms;
