import React, { FC, useEffect, useState } from "react";
import { Form, Input, Col, Row, message, DatePicker } from "antd";

import AmericanCard from "../../../../statics/assets/american-card.png";
import DiscoverCard from "../../../../statics/assets/discover.png";
import MasterCard from "../../../../statics/assets/master-card.png";
import VisaCard from "../../../../statics/assets/visa-card.png";
import { OrderSummaryInterface } from "../../../../interfaces/cardData";
import FormButtons from "../form-buttons";
import { formatCardNumber } from "../../../../utils/common-utils";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import dayjs from 'dayjs';

const dateFormatList = ["YYYY-MM", "YY-MM"];

const CardInfo: FC<OrderSummaryInterface> = ({
  next,
  prev,
  onCancel,
  current,
  trigger,
  confirmOrderData
}) => {
  const [form] = Form.useForm();
  const [expiryDate, setExpiryDate] = useState<any>();
  const setFormData = () => {
    confirmOrderData && form.setFieldsValue(confirmOrderData);
    confirmOrderData &&
      form.setFieldValue('expDate', confirmOrderData?.expDate ? moment(confirmOrderData?.expDate, dateFormatList[0]): null);
      setExpiryDate(confirmOrderData?.expDate);
  };

  useEffect(() => {
    document?.getElementById("cardName")?.focus();
    setFormData();
  },[])

  useEffect(() => {
    if (trigger !== 0 && current === 1) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = (values: any) => {
    values.expDate = expiryDate;
    next(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo?.errorFields[0]?.errors[0]);
  };

  const onDateChange = (date: any, dateString: string) => {
    setExpiryDate(dateString);
    console.log('data', dateString)
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  const handleChange = (event: any, type: string) => {
    let { value } = event.target;
    value = value.replace(/ /gi, "");
    if (isNaN(value)) {
      form?.setFieldsValue({
        [type]:
          type === "cardNumber"
            ? formatCardNumber(value.slice(0, -1))
            : value.slice(0, -1),
      });
      return;
    } else {
      form?.setFieldsValue({
        [type]: type === "cardNumber" ? formatCardNumber(value) : value,
      });
    }
  };
  
const validateMessages = {required: "${label} is required!"};

  return (
    <div className="step-form-fields">
      <Form
        name="cardInfo"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        autoComplete="off"
        layout="vertical"
        form={form}
        requiredMark={false}
        className="mb-74"
      >
        <Row gutter={[0, 10]} className="form-row" id="epik-scroll">
          <Col xs={{ span: 24 }}>
            <Form.Item
              name="cardName"
              label="Name On Card"
              rules={[{ required: true }]}
            >
              <Input id="cardName" autoFocus placeholder="Example John Smith" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }}>
            <Form.Item
              name="cardNumber"
              label="Credit Card Number"
              rules={[
                { required: true },
                {
                  min: 19,
                  message: "Card number is not valid",
                },
              ]}
            >
              <Input
                placeholder="0000 0000 0000 0000"
                maxLength={19}
                onChange={(event) => handleChange(event, "cardNumber")}
                suffix={
                  
                  <div className="card-images">
                    <div>
                      <img src={AmericanCard} alt="Card" />
                    </div>
                    <div>
                      <img src={DiscoverCard} alt="Card" />
                    </div>
                    <div>
                      <img src={MasterCard} alt="Card" />
                    </div>
                    <div>
                      <img src={VisaCard} alt="Card" />
                    </div>
                  </div>
                }
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            <Form.Item
              name="CVV"
              label="CVV"
              rules={[
                { required: true },
                {
                  min: 3,
                  message: "CVV is not valid",
                },
              ]}
            >
              <Input
                placeholder="0000"
                maxLength={4}
                onChange={(event) => handleChange(event, "CVV")}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 3 }}>
            <Form.Item
              name="expDate"
              label="Exp Date"
              rules={[{ required: true, message: "Please input date!" }]}
              className="receiving-date"
            >
              <DatePicker
                placeholder="YYYY-MM"
                format={dateFormatList}
                suffixIcon={false}
                bordered={false}
                id="submitText"
                style={{ width: "100%" }}
                onChange={onDateChange}
                picker="month" disabledDate={disabledDate}

              />
            </Form.Item>
          </Col>
        </Row>

        <FormButtons
          onCancel={onCancel}
          customClass={"confirmButtons"}
          nextButtonText={"Continue"}
          cancelButtonText={"Cancel"}
          handlePrevious={prev}
        />
      </Form>
    </div>
  );
};

export default CardInfo;
