import React from "react";
import { Carousel, Row, Col } from "antd";

import pinkLogo from "../../../../statics/assets/primaryLogo.svg";
import frameBG from "../../../../statics/assets/Frames/FrameBG.svg";
import wave from "../../../../statics/assets/Frames/purple-wave.svg";

import frame from "../../../../statics/assets/Frames/Frame.svg";
import Slide1 from "../../../../statics/assets/Frames/slide1.svg";
import Slide2 from "../../../../statics/assets/Frames/slide2.svg";
import Slide3 from "../../../../statics/assets/Frames/slide3.svg";
import Slide4 from "../../../../statics/assets/Frames/slide4.svg";

import "./landing.style.css";
import PaperHeader from "../../../paper-header";
import WavesFrame from "../../../waveFrame";
import PopoverButtton from "../../../popover-button";

const LandinBanner = () => {
  const TextList = [
    {
      text: "PASSION",
    },
    {
      text: "TEAMWORK",
    },
    {
      text: "BRAVERY",
    },
    {
      text: "HELPING",
    },
    {
      text: "GIVING",
    },
    {
      text: "DREAMING",
    },
    {
      text: "PASSION",
    },
  ];

  return (
    <WavesFrame BgImage={frameBG} bottomImage={wave}>
      <div className="landing-main">
        <Row gutter={[0, 24]} className="mt-5 app-padding">
          <Col xs={{ span: 22 }} md={{ span: 12 }} lg={{ span: 14 }}>
            <div className="landing-header app-title">
              <h1 className="landing_header_title">
                <div className="landing-slider">
                  <ul className="landing-slider__words">
                    {TextList &&
                      TextList.map(({ text }, index) => (
                        <li className="landing-slider__word" key={index}>
                          {text}
                        </li>
                      ))}
                  </ul>
                </div>
                <span className="d-flex align-center img-gap">
                  is <img src={pinkLogo} alt="Epik Card" />
                </span>
              </h1>
            </div>
            <PaperHeader
              title={
                "The Epik Gift Card is the first gift card specifically designed for kids and teens, redeemable virtually anywhere*."
              }
              fontSize="text-md mt-1-5"
              titleClass="landing-header-subtitle mb-0"
            />
            <PopoverButtton
              className="shadow-bottom buttonLarge mt-1-5"
              placement={"right"}
            />
          </Col>

          <Col xs={{ span: 22 }} md={{ span: 12 }} lg={{ span: 10 }}>
            <div
              className="right-frame text-center"
              style={{ backgroundImage: `url(${frame})` }}
            >
              <div className="frame-inner-img coming-soon-slider Epik-Landing-Crousel">
                <Carousel
                  effect="fade"
                  // autoPlaySpeed={9000}
                  speed={4000}
                  autoplay
                  dots={false}
                  easing="ease"
                  pauseOnHover={false}
                  useCSS={false}
                >
                  <div className="slide1">
                    <img src={Slide1} alt="Epik Gift Card" />
                  </div>{" "}
                  <div className="slide4">
                    <img
                      className="blue-border"
                      src={Slide4}
                      alt="Epik Gift Card"
                    />
                  </div>
                  <div className="slide2">
                    <img
                      className="blue-border"
                      src={Slide2}
                      alt="Epik Gift Card"
                    />
                  </div>
                  <div className="slide3">
                    <img
                      className="blue-border"
                      src={Slide3}
                      alt="Epik Gift Card"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </WavesFrame>
  );
};

export default LandinBanner;
