import React from "react";

import SenderDetail from "./basicForms/senderDetail";
import ReceiverForm from "./basicForms/receiverInfo";
import AmountForm from "./basicForms/amountForm";
import AddressForm from "./basicForms/addressForm";
import ShipmentDateForm from "./basicForms/shipmentDateForm";


export const physicalFormSteps: any = {
  0: {
    name: "senderForm",
    component: (props: any) => <SenderDetail {...props} />,
    listStep: 0,
  },
  1: {
    component: (props: any) => <ReceiverForm {...props} />,
    listStep: 1,
    name: "ReceiverForm",
  },
  2: {
    component: (props: any) => <AmountForm {...props} />,
    listStep: 2,
    name: "AmountForm",
  },
  3: {
    component: (props: any) => <AddressForm {...props} />,
    listStep: 3,
    name: "AddressForm",
  },
  4: {
    component: (props: any) => <ShipmentDateForm {...props} />,
    listStep: 4,
    name: "ShipmentDateForm",
  },
  5: {
    component: (props: any) => <></>,
    listStep: 5,
    name: "",
  },
  6: {
    component: (props: any) => <></>,
    listStep: 6,
    name: "",
  },


};

export const digitalFormSteps: any = {
  0: {
    name: "senderForm",
    component: (props: any) => <SenderDetail {...props} />,
    listStep: 0,
  },
  1: {
    component: (props: any) => <ReceiverForm {...props} />,
    listStep: 1,
    name: "ReceiverForm",
  },
  2: {
    component: (props: any) => <AmountForm {...props} />,
    listStep: 2,
    name: "AmountForm",
  },
  3: {
    component: (props: any) => <ShipmentDateForm {...props} />,
    listStep: 3,
    name: "ShipmentDateForm",
  },
  4: {
    component: (props: any) => <></>,
    listStep: 4,
    name: "",
  },
  5: {
    component: (props: any) => <></>,
    listStep: 5,
    name: "",
  },


};