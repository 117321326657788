import React from "react";
import { Layout } from "antd";

import './style.css';
import LandinBanner from "./Landing-Banner";
import SectionThird from "./Section3";
import SectionTwo from "./section-two";

const { Content } = Layout;

const WhoWeAre = () => {
  return (
    <Content>
      <LandinBanner />
      <SectionTwo />
      <SectionThird />
    </Content>
  );
};

export default WhoWeAre;
