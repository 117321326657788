export const getLocalData = (name: string) => JSON.parse(localStorage.getItem(name) || "{}");

export const getSingleCardFee = (data: any) => {

  var cardType = data?.cardType;
  cardType = cardType === 'bulk card' ? 'physical card' : cardType === 'digital card' ? "virtual card" : cardType;

  const cardFee = getLocalData('cardFee')?.find(
    ({ type }: any) => type.toLowerCase() === cardType.split(' ')[0]
  ).creation_fee;

  const totalCardValue = data?.cardType === "bulk card" ? +cardFee * +data?.totalQty : +cardFee
  return totalCardValue.toFixed(2);
};

export const getCardFee = (data: any) => {

  var cardType = data?.cardType;
  cardType = cardType === 'bulk card' ? 'physical card' : cardType === 'digital card' ? "virtual card" : cardType;

  const cardFee = getLocalData('cardFee')?.find(
    ({ type }: any) => type.toLowerCase() === cardType.split(' ')[0]
  ).creation_fee;

  return data?.cardType === "bulk card" ? +cardFee * +data?.totalQty : +cardFee;
};

export const getSipmentFee = (data: any) => +getLocalData('shipmentFee')?.find(({ type }: any) => type === data?.shipmentType).fee;

export const singleCardTotal = (data: any) => {
  const totalCardAmount =
    +getCardFee(data) +
    (data?.cardType === "digital card" ? 0 : getSipmentFee(data)) +
    (data?.cardType === "bulk card" ? (+data?.amount * +data?.totalQty) : +data?.amount)
  return totalCardAmount.toFixed(2);
};

export const orderTotal = (data: any) => {
  let totalAmount: any = 0;
  data.forEach((element: any) => {
    totalAmount = totalAmount + +singleCardTotal(element)
  });

  return `$${totalAmount.toFixed(2)}`;
};

export const getShipmentDays = (shipmentType: any) => {
  const cardTypeDays = getLocalData('cardFee')?.find(({ type }: any) => type === 'PHYSICAL').days;
  const shipmentDays = getLocalData('shipmentFee')?.find(({ type }: any) => type === shipmentType).days;

  return +cardTypeDays + +shipmentDays;
};