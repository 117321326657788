import React, { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

import dotImg from "../../statics/assets/small_.........svg";

import "./footer.style.css";
import { Layout, Col, Row, Drawer } from "antd";
import PaperHeader from "../../components/paper-header";
import LinkText from "../../components/link-text";
import CopyRights from "./copy-rights";
import CheckBalance from "../../components/ContentArea/check-balance";
import useStyles from "../../hooks/use-styles";
import { Link } from "react-router-dom";

// const {  } = Layout;

const Footer = (props: { path: any }) => {
  const [footerColor, setFooterColor] = useState("#ffffff");
  const [showCheckBalance, setShowCheckBalance] = useState(false);
  const { primaryLabelColor } = useStyles(["primaryLabelColor"]);

  const URLS = ["/", "/why-it-works", "/who-we-are", "/register-card", "/activate-card"];
  const styles = {
    body: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column" as "column",
    },
  };

  useEffect(() => {
    URLS.includes(props.path)
      ? setFooterColor("#F9E8EB")
      : setFooterColor("#FFFFFF");
  }, [props.path]);

  const showDrawer = () => {
    setShowCheckBalance(true);
  };

  const onClose = () => {
    setShowCheckBalance(false);
  };

  return (
    <>
      <Layout className="footer-container" style={{ background: footerColor }}>
        <Row gutter={[0, 24]}>
          <Col span={22} offset={1}>
            <img className="dotted-line w-100" src={dotImg} alt="Dots" />
          </Col>
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 7 }} md={{ span: 4 }}>
            <PaperHeader title={"Contact"} titleClass="bold mb-12" />
            <PaperHeader title={<a href="mailto:info@myepik.com" >info@myepik.com</a>} titleClass="mb-12" />
            <PaperHeader title={"+1 844 404 0264"} titleClass="mb-12" />
          </Col>

          <Col xs={{ span: 11, offset: 1 }} sm={{ span: 7 }} md={{ span: 4 }}>
            <LinkText
              text={"Why it Works"}
              link={"/why-it-works"}
              fontSize="text-md"
              className="achorTag bold mb-12"
            ></LinkText>
            <LinkText
              text={"Contact"}
              link={"/contact-us"}
              fontSize="text-md"
              className="achorTag bold mb-12"
            ></LinkText>
            <LinkText
              text={"Who We Are"}
              link={"/who-we-are"}
              fontSize="text-md"
              className="achorTag bold mb-12"
            ></LinkText>
          </Col>

          <Col xs={{ span: 11, offset: 1 }} sm={{ span: 7 }} md={{ span: 4 }}>
            <LinkText
              text={"Check Balance"}
              link={" "}
              fontSize="text-md"
              className="achorTag bold mb-12"
              onClick={showDrawer}
            ></LinkText>
            <Drawer
              title="Check Balance"
              height="auto"
              bodyStyle={styles.body}
              className="custom-drawer"
              placement="top"
              closable={true}
              onClose={onClose}
              open={showCheckBalance}
              size="default"
              closeIcon={<CloseCircleOutlined />}
            >
              <CheckBalance reset={showCheckBalance} />
            </Drawer>
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 2 }}
            md={{ span: 6, offset: 2 }}
            lg={{ span: 6, offset: 2 }}
          >
            <PaperHeader
              title={
                "The Epik Gift Card is the perfect fun and purposeful reward for kids and teens."
              }
              fontSize="text-sm"
            ></PaperHeader>

            <h1
              className="text-sm weight-400"
              style={{ color: primaryLabelColor }}
            >
              *Please refer to{" "}
              <Link
                className="weight-500"
                style={{ color: primaryLabelColor }}
                to={"/FAQs"}
              >
                FAQs
              </Link>{" "}
              for approved list of merchants
            </h1>
          </Col>

          <Col xs={{ span: 22, offset: 1 }} >
          <PaperHeader
              title={
                "Card is issued by Sutton Bank, Member FDIC. For customer service call +1 844 404 0264."
              }
              fontSize="text-sm"
              headerClass="mt-2"
            ></PaperHeader>
          </Col>
        </Row>
      </Layout>
      <CopyRights></CopyRights>
    </>
  );
};

export default Footer;
