import React, {FC, useEffect, useState} from "react";
import {Col, Form, message, Modal, Row} from "antd";
import "../style.css";
import PaperHeader from "../../../paper-header";
import { registerCard} from "../../../../apis/auth_api";
import {getErrorMessage, padLastFour} from "../../../../utils/common-utils";
import WavesFrame from "../../../waveFrame";
import CustomButton from "../../../custom-button";
import {RegisterCardInterface} from "../../../../interfaces/cardData";
import { useNavigate } from "react-router-dom";
import {setLoadingState} from "../../../../app-store/user/user-reducer";
import {useDispatch} from "react-redux";

const RegisterCardConfirm: FC<RegisterCardInterface> = (
  {
    prev,
    cardData,
    userData,
  }
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const openRegisterCardModal = () => {
    setConfirmationModal(true);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });

  const handleRegisterCard = () => {
    dispatch(setLoadingState(true));
    setShowError(false);
    setConfirmationModal(false);
    registerCard(userData)
      .then((res) => {
        if (res?.status === 200) {
          message.success({
            content: "Your card has been registered successfully ",
            className: "register-success-notify-wrapper",
            duration: 10
          });
          dispatch(setLoadingState(false));
          navigate("/");
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          setErrorMessage(res?.message);
          setShowError(true);
          dispatch(setLoadingState(false));
        }
      })
      .catch((error) => {
        // Handle error
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setErrorMessage(getErrorMessage(error));
        setShowError(true);
        dispatch(setLoadingState(false));
      });
  };

  return (
    <WavesFrame BgColor="#FFFFFF">
      <div>
        <Row>
          <Col span={24}>
            {showError && errorMessage &&
              <Row justify="center" className="mb-12">
                <Col span={21} className="error-msg-wrapper">
                  <PaperHeader
                    title={errorMessage}
                    fontSize="text-md error-msg-text"
                    titleClass="extraBold"
                  />
                </Col>
              </Row>
            }
            <Row className="mb-40">
              <Col>
                <h1 className="primaryColor app-secondary-title extraBold mb-0">Register a Card</h1>
                <PaperHeader
                  title={"Register your Physical Card to your name to avail the card replacement in case of any Fraud or Lost Stolen."}
                  fontSize="text-20"
                />
              </Col>
            </Row>

            <Row className="review-data-wrapper mb-60">
              <Col xs={{span: 19, offset: 3}} md={{span: 22, offset: 2}}>
                <Row>
                  <Col>
                    <PaperHeader
                      title={"Review"}
                      fontSize="text-25"
                      titleClass="bold"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={"Card number"}
                          fontSize="text-base"
                          titleClass="weight-500 fieldLabel"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {cardData?.cardNumber? (
                          <PaperHeader
                            title={padLastFour(cardData.cardNumber?.substring(cardData.cardNumber.length-5, cardData.cardNumber.length-1))}
                            fontSize="text-md"
                            titleClass="bold"
                            titleColor="black"
                          />
                        ):(<></>)}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col xs={{span: 22}} md={{span: 4}}>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={"First name"}
                          fontSize="text-base"
                          titleClass="weight-500 fieldLabel"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={userData?.firstName}
                          fontSize="text-md"
                          titleClass="bold"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={{span: 22}} md={{span: 4}}>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={"Last name"}
                          fontSize="text-base"
                          titleClass="weight-500 fieldLabel"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={userData?.lastName}
                          fontSize="text-md"
                          titleClass="bold"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={{span: 22}} md={{span: 8}}>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={"Email"}
                          fontSize="text-base"
                          titleClass="weight-500 fieldLabel"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={userData?.email}
                          fontSize="text-md"
                          titleClass="bold"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={{span: 22}} md={{span: 4}}>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={"Mobile"}
                          fontSize="text-base"
                          titleClass="weight-500 fieldLabel"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={userData?.mobile}
                          fontSize="text-md"
                          titleClass="bold"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <PaperHeader
                          title={"Address"}
                          fontSize="text-base"
                          titleClass="weight-500 fieldLabel"
                          titleColor="black"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {userData? (
                          <PaperHeader
                            title={
                              userData.address1?.concat(userData.address2? ", " + userData.address2: "")
                              + ", "  + userData.city
                              + " " + userData.state
                              + " " + userData.zipCode
                            }
                            fontSize="text-md"
                            titleClass="bold"
                            titleColor="black"
                          ></PaperHeader>
                        ): (<></>)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[16,0]} className="review-data-buttons" justify="center">
              <Col xs={{span: 11, offset: 1}} md={{span: 4, offset: 0}}>
                <Form.Item>
                  <CustomButton
                    type="primary"
                    htmltype="button"
                    text="Back"
                    onClick={prev}
                    className="shadow-bottom back-button mt-1-5"
                  ></CustomButton>
                </Form.Item>
              </Col>
              <Col xs={{span: 11, offset: 1}} md={{span: 4, offset: 0}}>
                <Form.Item>
                  <CustomButton
                    type="primary"
                    htmltype="button"
                    text="Continue"
                    onClick={openRegisterCardModal}
                    className="shadow-bottom register-button mt-1-5"
                  ></CustomButton>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          centered
          closable={false}
          open={confirmationModal}
          onOk={() => handleRegisterCard()}
          onCancel={() => setConfirmationModal(false)}
          okText="Yes"
          cancelText="Cancel"
          className="order-modal"
          width={600}
        >
          <div style={{textAlign: "center"}}>
            <h2>Are you sure</h2>
            <p>You want to register the card with the provided personal information?</p>
          </div>
        </Modal>
      </div>
    </WavesFrame>
  );
};

export default RegisterCardConfirm;
