import moment, { Moment, unitOfTime } from "moment";
import customToast from "../components/shared/services/toaster-service";
import useConfigs from "../hooks/use-config";
import { findErrorMessage } from "./metadata";
import { message } from "antd";

export const RECIPIENT_TYPE = "P2P";

export const validateZipCode = (_: any, value: string | number) => {
  const regex = /^\d{5,9}$/; // Regular expression for 5 to 9 digits
  if (!value || regex.test(value.toString())) {
    return Promise.resolve();
  }
  return Promise.reject('Please enter a 5-9 digit Number.');
};

export const showErrorNotification = (error: any) => {
  if (typeof error === "string") {
    customToast.error(error);
  } else {
    let getMessage = "";
    if (error.error_code) {
      getMessage = findErrorMessage(error.error_code, "en");
      if (!getMessage) {
        console.error(
          `Could not find any message against [${error.error_code}]`
        );
      }
    }

    if (!getMessage) {
      getMessage = error.message;
    }
    message.error(getMessage);
  }
};

export const getErrorMessage = (error: any) => {
  let getMessage = "";
  if (typeof error === "string") {
    getMessage = error;
  } else {
    if (error.error_code) {
      getMessage = findErrorMessage(error.error_code, "en");
      if (!getMessage) {
        console.error(
          `Could not find any message against [${error.error_code}]`
        );
      }
    }

    if (!getMessage) {
      getMessage = error.message;
    }
  }
  return getMessage;
};

export const isJSON = (val: string): boolean => {
  if (typeof val === "string" && val.length > 1) {
    const startChar = val[0];
    const endChar = val[val.length - 1];
    return ["{", "["].includes(startChar) && ["]", "}"].includes(endChar);
  }

  return false;
};

export const timeDiff = (
  from: any,
  to: any,
  unit: unitOfTime.Diff = "seconds"
): number => {
  return moment(from).diff(to, unit);
};

export const timeDiffFromNow = (time: Moment, unit = "seconds"): number => {
  return timeDiff(time, moment(), unit as unitOfTime.Diff);
};

export const padStart = (value: string, length: number, character: string) => {
  return value.padStart(length, character);
};

export const addSpaceInCardNumber = (cardNumber: string) => {
  return cardNumber.match(/.{1,4}/g)?.join(" ");
};

export const formatLastFour = (cardNumber: string) => {
  return addSpaceInCardNumber(padStart(cardNumber, 16, "*"));
};

export const padLastFour = (lastFour: string) => {
  return lastFour.padStart(9, "**** ");
};

export const padLastFive = (lastFive: string) => {
  return lastFive.padStart(10, "***** ");
};

export const previewMaskSsn = (ssnNumber: string) => {
  const lastFour = ssnNumber.slice(ssnNumber.length - 4);
  return `***-**-${lastFour}`;
};

export const maskSSN = (ssnNumber: any) => {
  if (ssnNumber.length > 0) {
    const ssnDigits = ssnNumber.length - 1;
    const lastDigit = ssnNumber[ssnDigits];
    let ssn = "";
    const mask = "*";
    for (var i = 0; i < ssnDigits; i++) {
      ssn = `${ssn}${mask}`;
    }
    const finalMask = `${ssn}${lastDigit}`;
    return finalMask;
  } else {
    return "";
  }
};

export const formatName = (firstName: string, lastName: string) => {
  return `${firstName[0].toUpperCase()}.${lastName}`;
};

export const formatCurrency = (amount: number, currency: string) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });

  return formatter.format(amount);
};

export const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const decimalCount = (num: string) => {
  const numStr = String(num);
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  return 0;
};

export const startOfWeek = () => {
  return moment().startOf("week");
};

export const endOfWeek = () => {
  return moment().endOf("week");
};

export const isEmpty = (objectDetails: any) => {
  return !Object.keys(objectDetails).length;
};

export const StateName = (stateCode: string) => {
  const { states } = useConfigs(["states"]);
  const state = states.find(({ code }: any) => code === stateCode);
  return state?.name || stateCode;
};

export const convertStringToDate = (str: string) => {
  const [month, day, year] = str.split("/");
  return new Date(+year, +month - 1, +day);
};

export function formatCardNumber(value: any) {
  let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  let matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || "";
  let parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join(" ");
  } else {
    return value;
  }
}

export const formatPhoneNumber = (input: string): string => {
  input = input.replace(/\D/g, "").substring(0, 10); //Strip everything but 1st 10 digits
  var size = input.length;
  if (size > 0) {
    input = "(" + input;
  }
  if (size > 3) {
    input = input.slice(0, 4) + ") " + input.slice(4);
  }
  if (size > 6) {
    input = input.slice(0, 9) + " " + input.slice(9);
  }
  return input;

  // Apply phone number format using regular expression
  // const formattedNumber = numericInput.replace(
  //   /^(\d{3})(\d{3})(\d{4})$/,
  //   '($1) $2-$3'
  // );
};
