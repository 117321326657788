import {Button, Modal, Popover} from "antd";
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../custom-button";
import rightArrow from "../../statics/assets/rightArrow.png";
import "./popover-button.style.css";

interface PopoverButttonProps {
  className?: string;
  backgroundColor?: string;
  text?: string;
  placement?: any;
}

const PopoverButtton = ({
  text,
  className,
  backgroundColor,
  placement,
}: PopoverButttonProps) => {
  const navigate = useNavigate();
  // const [isModalVisible, setIsModalVisible] = useState(false);

  const [isTypeChangedModalVisible, setIsTypeChangedModalVisible] = useState(false);
  const [selectedCardType, setSelectedCardType] = useState('');
  const [existingOrderCardType, setExistingOrderCardType] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://c.amazon-adsystem.com/aat/amzn.js';
    script.id = 'amzn-pixel';
    script.async = true;
    document.body.appendChild(script);
  
    script.onload = () => {
      window.amzn('setRegion', 'NA');
      window.amzn('addTag', 'f4ebfe97-3096-4d6e-808f-ef67bda1a240');
    };
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {});

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  // const isDigitalCardFun = (value: any) => {
  //   showModal();
  // };

  const checkIfCardTypeChanged = (cardType: string): boolean => {
    let isCardTypeChange: boolean = false;
    const storageCardsList = localStorage.getItem("cardsList");
    if (storageCardsList) {
      const cardList =  JSON.parse(storageCardsList);
      if(Array.isArray(cardList) && cardList.length > 0 && cardList[0].cardType !== cardType) {
        isCardTypeChange = true;
        setExistingOrderCardType(cardList[0].cardType)
      }
    }
    return isCardTypeChange;
  };

  const handleChangeCardType = () => {
    localStorage.removeItem("cardsList");
    localStorage.removeItem("senderDetail");
    setIsTypeChangedModalVisible(false);
    navigate("/order-epik-card?type=" + selectedCardType)
  };

  const openOrderEpikCard = (cardType: string) => {
    if (window.amzn && cardType !== "bulk card") {
      window.amzn('trackEvent', 'Lead_My Epik BuyAnEpikGiftCardButton IHM IS');
    }
    setSelectedCardType(cardType);
    if(checkIfCardTypeChanged(cardType)) {
      setIsTypeChangedModalVisible(true);
    } else {
      navigate("/order-epik-card?type=" + cardType)
    }
  }

  const content = (
    <div className="digi-card-btns">
      <Button
        onClick={() => openOrderEpikCard("physical card")}
        type="link"
      >
        Physical Card <img src={rightArrow} alt="card" />
      </Button>
      <Button
        onClick={() => openOrderEpikCard("digital card")}
        type="link"
      >
        Digital Card <img src={rightArrow} alt="card" />
      </Button>
      <Button
        onClick={() => openOrderEpikCard("bulk card")}
        type="link"
      >
        Bulk Buying <img src={rightArrow} alt="card" />
      </Button>
    </div>
  );

  return (
    <>
      <Popover
        overlayClassName="banner-popup-two"
        content={content}
        title={false}
        trigger="click"
        placement={placement ? placement : "bottom"}
      >
        <CustomButton
          type="primary"
          text={text ? text : "Buy an Epik Gift Card"}
          className={className}
          backgroundColor={backgroundColor!}
        ></CustomButton>
      </Popover>
      {/* <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={false}
        className="epik-card-modal"
        width="100%"
      >
        {isModalVisible && (
          <EpikCardSteps />
        )}
      </Modal> */}
      <Modal
        centered
        open={isTypeChangedModalVisible}
        closable={false}
        onCancel={() => setIsTypeChangedModalVisible(false)}
        onOk={() => handleChangeCardType()}
        okText="Yes"
        cancelText="No"
        className="order-modal"
        width={600}
      >
        <div style={{ textAlign: "center" }}>
          <h2>Are you sure</h2>
          <p>You already have {existingOrderCardType} order in your cart. If you continue, your existing order details will be lost.</p>
        </div>
      </Modal>
    </>
  );
};

export default PopoverButtton;
