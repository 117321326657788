import React, { FC, useEffect } from "react";
import { Form, Input, message, Row, Col } from "antd";
import { orderBasinInterface } from "../../../../interfaces/cardData";

const ReceiverForm: FC<orderBasinInterface> = ({ next, current, trigger, data, listStep  }) => {
  const [form] = Form.useForm();
  const setFormData = () => {
    data && form.setFieldsValue(data);
  };

  useEffect(() => {
    setFormData();
    var input = document?.getElementById("receiverName")?.focus();
    if (current === listStep) {
      return input;
    }
  }, [current]);

  // const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
  //   console.log(event.key);
  //   if (event.key === "Enter") {
  //     next();
  //   }
  // };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    }
  };

  useEffect(() => {
    if (trigger !== 0 && current === listStep) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = (values: any) => {
    next(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo?.errorFields[0]?.errors[0]);
  };

  return (
    <Form
      name="receiverForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
      autoComplete="off"
      form={form}
      layout="vertical"
      requiredMark={false}
    >
      <div className="step-form-fields">
        <Row gutter={[0, 0]}>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <Form.Item
              name="receiverName"
              label="Receiver Name"
              rules={[{ required: true}]}
            >
              <Input id="receiverName" placeholder="Enter Name" maxLength={26} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 18 }}>
            <Form.Item
              name="receiverEmail"
              label="Receiver Email"
              rules={[
                { required: true, type: "email" },
              ]}
            >
              <Input  placeholder="Enter Email" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 22 }}>
            <Form.Item name="message" label="Write a message for receiver">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ReceiverForm;
