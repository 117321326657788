import React from "react";
import { Layout } from "antd";

import LandinBanner from "./LandinBanner";
import SectionTwo from "./section-two";
import SectionThree from "./section-three";
import SectionFour from "./section-four";

const { Content } = Layout;

const WhyItWorksPage = () => {
  return (
    <Content>
      <LandinBanner />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
    </Content>
  );
};

export default WhyItWorksPage;
