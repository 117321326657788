import React from "react";
import { Row, Col } from "antd";

import bottomWave from "../../../../statics/assets/Frames/why-it-works/section4Bottom.svg";
import frameBG2 from "../../../../statics/assets/Frames/section3Frame2.svg";
import { ReactComponent as Image } from "../../../../statics/assets/Frames/section3image2.svg";

import WavesFrame from "../../../waveFrame";
import PopoverButtton from "../../../popover-button";

const SectionFour = () => {
  const bgColor = "linear-gradient(90deg, #CE0F69 0%, #9595D2 100%)";

  return (
    <WavesFrame
      BgColor={bgColor}
      bottomImage={bottomWave}
      bottomImageColor="#F9E8EB"
    >
      <Row
        gutter={[0, 24]}
        className="section3-main app-padding"
        style={{ paddingTop: "5rem" }}
      >
        <Col
          xs={{ span: 24 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 10, offset: 7 }}
        >
          <div
            className="right-frame text-center blue-border"
            style={{ backgroundImage: `url(${frameBG2})` }}
          >
            <div className="frame-inner-img">
              <div className="position-relative">
                <Image style={{ background: bgColor }} />
                <div className="text">
                  <h4 className="title bold mb-0">Buy Now</h4>
                  <PopoverButtton
                    className="text-md shadow-bottom buttonLarge mt-1"
                    backgroundColor="white"
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default SectionFour;
