import React from "react";
import { Layout, Col, Row } from "antd";
import facebook from "../../../statics/assets/socials/fb.svg";
import tiktok from "../../../statics/assets/socials/tiktok.svg";
import youtube from "../../../statics/assets/socials/utube.svg";
import insta from "../../../statics/assets/socials/insta.svg";
import Twitter from "../../../statics/assets/socials/twitter.svg";
import Snap from "../../../statics/assets/socials/snap.svg";
import Linktr from "../../../statics/assets/socials/linktr.png";

import PaperHeader from "../../../components/paper-header";
import LinkText from "../../../components/link-text";

const CopyRights = () => {
  return (
    <Layout className="copyright-container">
      <Row justify="space-between" gutter={[0, 24]}>
        <Col span={22} offset={1}>
          <div className="d-flex flex-wrap justify-between row-gap-12">
            <span className="d-flex gap-24">
              <a href="https://www.youtube.com/@myepikcard" target={"_blank"}>
                <img src={youtube} alt="social icon" />
              </a>
              <a
                href="https://www.tiktok.com/@epikgiftcard?lang=en"
                target={"_blank"}
              >
                <img src={tiktok} alt="social icon" />
              </a>
              <a
                href="https://www.instagram.com/epikgiftcard"
                target={"_blank"}
              >
                <img src={insta} alt="social icon" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100092290997740" target={"_blank"}>
                <img src={facebook} alt="social icon" />
              </a>

              <a href="https://twitter.com/epikgiftcard" target={"_blank"}>
                <img src={Twitter} alt="social icon" />
              </a>
              <a href="https://www.snapchat.com/add/myepikcard" target={"_blank"}>
                <img src={Snap} alt="social icon" />
              </a>
              <a href="https://linktr.ee/myepikcard" target={"_blank"}>
                <img src={Linktr} alt="social icon" width={23} />
              </a>
            </span>
            <span className="d-flex justify-end align-center gap-24">
              <PaperHeader
                title={"© 2023 My Epik Inc., all rights reserved."}
                fontSize="text-sm"
                titleColor="#D6D6DE"
                titleClass="mb-0"
              />
              <LinkText
                text={"Cardholder Agreement"}
                link={"/terms"}
                className="mb-0 w-500"
                color="white"
              />
              <LinkText
                text={"Privacy"}
                link={"/privacy-policy"}
                className="mb-0 w-500"
                color="white"
              />
              <LinkText
                text={"FAQ"}
                link={"/FAQs"}
                className="mb-0 w-500"
                color="white"
              />
            </span>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default CopyRights;
