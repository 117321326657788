import React, { FC, useEffect } from "react";
import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import "../style.css";
import PaperHeader from "../../../paper-header";
import {
  formatPhoneNumber, validateZipCode,
} from "../../../../utils/common-utils";
import WavesFrame from "../../../waveFrame";
import CustomButton from "../../../custom-button";
import { CaretDownOutlined } from "@ant-design/icons";
import useConfigs from "../../../../hooks/use-config";
import { RegisterCardInterface, UserData } from "../../../../interfaces/cardData";
import { setLoadingState } from "../../../../app-store/user/user-reducer";
import { useDispatch } from "react-redux";

const { Option } = Select;

const RegisterCardForm: FC<RegisterCardInterface> = (
  {
    next,
    userData,
    cardData,
    handleSetUserData,
  }
) => {
  const dispatch = useDispatch();
  const formRef = React.useRef<FormInstance>(null);
  const [form] = Form.useForm();
  const { states, countries } = useConfigs(["states", "countries"]);
  const setFormData = () => {
    userData && form.setFieldsValue(userData);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setFormData();
    document?.getElementById("firstName")?.focus();
  });

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = (values: UserData) => {
    dispatch(setLoadingState(true));
    if (cardData) {
      values.cardId = cardData.cardId;
    }
    handleSetUserData(values);
    dispatch(setLoadingState(false));
    next();

  }

  const handleMobileChange = (event: any) => {
    let { value } = event.target;
    value = value.replace(/ /gi, "");
    form?.setFieldsValue({
      "mobile": formatPhoneNumber(value),
    });
  };

  return (
    <WavesFrame BgColor="#FFFFFF">
      <Row>
        <Col span={24}>
          <Row className="mb-15">
            <Col>
              <h1 className="primaryColor app-secondary-title extraBold mb-0">Register a Card</h1>
              <PaperHeader
                title={"Register your Physical Card to your name to avail the card replacement in case of any Fraud or Lost Stolen."}
                fontSize="text-20"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 22 }} md={{ span: 24 }}>
              <Form
                // {...layout}
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                size="large"
                validateMessages={validateMessages}
                form={form}
                className="register-card-form custom"
                autoComplete="off"
                requiredMark={false}
              >
                <Row className="mb-25">
                  <Col span={24}>
                    <Row gutter={[0, 24]}>
                      <Col>
                        <PaperHeader
                          title={"Personal Info"}
                          fontSize="text-lg"
                          titleClass="bold link"
                        />
                      </Col>
                    </Row>
                    <Row gutter={[32, 0]}>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item
                          name="firstName"
                          className={'required-label'}
                          label="First Name"
                          rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item
                          name="lastName"
                          className={'required-label'}
                          label="Last Name"
                          rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item
                          name="email"
                          className={'required-label'}
                          label="Email"
                          rules={[{ required: true, type: "email" }]}>
                          <Input maxLength={50} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[32, 0]}>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item
                          name="mobile"
                          label="Mobile"
                          className={'required-label'}
                          rules={[
                            { required: true, message: "Mobile number is required!" },
                            { min: 14, message: 'Enter a 10-digit number.' }
                          ]}
                        >
                          <Input
                            placeholder="(xxx) 777 7777"
                            maxLength={14}
                            onChange={(event) => handleMobileChange(event)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mb-15">
                  <Col span={24}>
                    <Row gutter={[0, 24]}>
                      <Col>
                        <PaperHeader
                          title={"Address Info"}
                          fontSize="text-lg"
                          titleClass="bold link"
                        />
                      </Col>
                    </Row>

                    <Row gutter={[32, 0]}>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item
                          name="address1"
                          className={'required-label'}
                          label="Street Address"
                          rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item name="address2" label="Apt, Suite, etc">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item name="city" label="City" className={'required-label'} rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[32, 0]}>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item
                          name="state"
                          className={'required-label'}
                          label="State"
                          rules={[{ required: true }]}
                        // initialValue={''}
                        >
                          <Select
                            placeholder="Select State"
                            placement="topLeft"
                            style={{ width: "100%" }}
                            showSearch
                            suffixIcon={<CaretDownOutlined />}
                            filterOption={(input: any, option: any) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                              0
                            }
                            getPopupContainer={(trigger) => trigger.parentElement}
                            dropdownMatchSelectWidth={false}
                          // dropdownAlign={{ offset: [0, 4] }}
                          >
                            {states &&
                              states.map((item: any, index: any) => (
                                <Option key={index} value={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item
                          name={'zipCode'}
                          label={'Zip Code'}
                          rules={[{ required: true, message: 'This is a Required Field' },
                          { min: 5, max: 9, validator: validateZipCode, message: 'Zip Code must be a 5-9 digit number' },]}
                        >
                          <Input placeholder={'Zip Code'} minLength={5} maxLength={9} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 22 }} md={{ span: 8 }}>
                        <Form.Item
                          name="country"
                          className={'required-label'}
                          label="Country"
                          rules={[{ required: true }]}
                          initialValue={"USA"}
                        >
                          <Select
                            placeholder="Select Country"
                            placement="bottomLeft"
                            style={{ width: "100%" }}
                            showSearch
                            suffixIcon={<CaretDownOutlined />}
                            filterOption={(input: any, option: any) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                              0
                            }
                            getPopupContainer={(trigger) => trigger.parentElement}
                            dropdownMatchSelectWidth={false}
                          // dropdownAlign={{ offset: [0, 4] }}
                          >
                            {countries &&
                              countries.map((item: any, index: any) => (
                                <Option key={index} value={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xs={{ span: 24 }} md={{ span: 4 }}>
                    <Form.Item
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                      <CustomButton
                        type="primary"
                        htmltype="submit"
                        text="Continue"
                        className="shadow-bottom continue-button mt-1-5"
                      ></CustomButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </WavesFrame>
  );
};

export default RegisterCardForm;
