import React from "react";
import { Row, Col } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";


import frame from "../../../../statics/assets/Frames/section2Frame.svg";

import "./section2.style.css";
import PaperHeader from "../../../paper-header";
import LinkText from "../../../link-text";

const WhoWeAreSection = () => {
  return (
    <>
      <div className="landing-section2 app-padding">
        <Row gutter={[0, 24]} justify="space-between">
          <Col
            xs={{ span: 22}}
            md={{ span: 12}}
            lg={{ span: 10}}
          >
            <h1 className="title app-secondary-title">
              An Exclusive Gift Card for Epik Effort <span className="weight-500">™</span>
            </h1>
            <PaperHeader
              title={
                "The Epik Gift Card is a thoughtful gift created for kids and teens to recognize and reward exceptional behavior, which we consider Epik Effort™. Epik Gift Cards can be redeemed virtually anywhere for stuff like food, entertainment, apparel, accessories, and more. However, The Epik Card is designed to work at sites and stores that are age appropriate."
              }
              fontSize="text-md mt-1-5"
              titleClass="landing-header-subtitle mb-0"
            />

            <LinkText
              text={"Who We Are"}
              link={"/who-we-are"}
              fontSize="text-md"
              className="mt-2 bold"
              color= '#1B1B5B'
            ><ArrowRightOutlined />
            </LinkText>

          </Col>

          <Col
            xs={{ span: 22 }}
            md={{ span: 12 }}
            lg={{ span: 10}}
          >
            <img className="right-img text-center"  src={frame} alt="Epik Gift Card" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default WhoWeAreSection;
