import { createSlice } from "@reduxjs/toolkit";
import User from "../../interfaces/user";
import postMessageToParent from "../../utils/post-message-to-parent"

const getLocalState = (key:string) => {
  const localState = localStorage.getItem(key);
  if (localState) {
    return JSON.parse(localState);
  }

  return {};
};

const userState = {
  activeUser: getLocalState('activeUser'),
  userProfile: getLocalState('userProfile'),
  loading: false,
};

const userSlice = createSlice({
  name: "users",
  initialState: userState,
  reducers: {
    setLoginUser: (state, action) => {
      localStorage.setItem("activeUser", JSON.stringify(action.payload));
      state.activeUser = action.payload;
      postMessageToParent("api.auth.success", action.payload);
    },
    setUserProfile: (state, action) => {
      localStorage.setItem("userProfile", JSON.stringify(action.payload));
      state.userProfile = action.payload;
    },
    setLoadingState: (state, action) => {
      console.log(action)
      state.loading = action.payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem("activeUser");
      localStorage.removeItem("userProfile");
      state.activeUser = {} as User;
    },
  },
});

export const { setLoginUser, logoutUser, setUserProfile, setLoadingState } = userSlice.actions;
export default userSlice.reducer;
